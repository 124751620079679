import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import './Slider.css';

const slides = [
  {
    img: '../assets/Image.png',
    h3: '— COUPLE EDITION',
    h1: 'Shop All items here under',
    span: '60 USD',
  },
  {
    img: '../assets/Image.png',
    h3: '— COUPLE EDITION',
    h1: 'Shop All items here under',
    span: '60 USD',
  },
  {
    img: '../assets/Image.png',
    h3: '— COUPLE EDITION',
    h1: 'Shop All items here under',
    span: '60 USD',
  },
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  // Navigate to products page
  const goToProductsPage = () => {
    navigate('/products');
  };

  // Handle screen resize for responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // // Function to handle going to the previous slide
  // const prevSlide = () => {
  //   setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  // };

  // // Function to handle going to the next slide
  // const nextSlide = () => {
  //   setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  // };

  return (
    <div className='slider-container'>
      <section className='slider'>
        <div className='container'>

          {/* Arrow for navigating to the previous slide */}
          {/* <IoIosArrowBack className='slider__arrow slider__arrow--left' onClick={prevSlide} /> */}

          <div className={`slider__content${isResponsive ? ' column' : ''}`}>
            {isResponsive ? (
              <>
                <div className='slider__text'>
                  <h3>{slides[currentSlide].h3}</h3>
                  <h1>{slides[currentSlide].h1} <span className="underlined">{slides[currentSlide].span}</span></h1>
                </div>
                <div className='slider__img'>
                  <img src={slides[currentSlide].img} alt={`Slide ${currentSlide + 1}`} />
                </div>
                <button onClick={goToProductsPage}>SHOP NOW <IoIosArrowForward className='slider-arrow-icon' /></button>
              </>

            ) : (

              <>
                <div className='slider__text'>
                  <h3>{slides[currentSlide].h3}</h3>

                  <h1>{slides[currentSlide].h1}<span className="underlined">
                    {slides[currentSlide].span}</span>
                  </h1>

                  <button onClick={goToProductsPage}>
                    SHOP NOW 
                    <IoIosArrowForward className='slider-arrow-icon' />
                  </button>
                </div>
                <div className='slider__img__main'>
                  <img src={slides[currentSlide].img} alt={`Slide ${currentSlide + 1}`} />
                </div>
              </>
            )}
          </div>

          {/* Arrow for navigating to the next slide */}
          {/* <IoIosArrowForward className='slider__arrow slider__arrow--right' onClick={nextSlide} /> */}

        </div>
      </section>
    </div>
  );
};

export default Slider;
