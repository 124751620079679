// import React, { useState } from 'react';
// import { FaCircleCheck } from "react-icons/fa6";
// import { useNavigate } from 'react-router-dom';
// import { FaUser } from 'react-icons/fa';

// // import Nav from '../../Filteration/Navigation/Nav';

// import './Payment.css';

// const Payment = () => {
//     const navigate = useNavigate();

//     const goToCurrentOrder = () => {
//         navigate('/CheckOut');
//     };
//     const [activeSection, setActiveSection] = useState('customer');

//     const handleSectionClick = (section) => {
//         setActiveSection(section);
//     };

//     return (
//         // Check Out 
//         <>
//         {/* <Nav /> */}
//         <div className="container">
//             <div className='left-card-payment '>

//                 <div className="left-section1">
//                     <span className={activeSection === 'customer' ? 'active' : ''} onClick={() => handleSectionClick('customer')}>
//                         <FaCircleCheck className={activeSection === 'customer' ? 'active-check-icon active' : 'active-check-icon'} /> Customer Information
//                     </span>
//                     <span className={activeSection === 'payment' ? 'active' : ''} onClick={() => handleSectionClick('payment')}>
//                         <FaCircleCheck className={activeSection === 'payment' ? 'active-check-icon active' : 'active-check-icon'} /> Payment Details
//                     </span>
//                     <div className={activeSection === 'customer' ? 'progress-line active' : 'progress-line'}></div>
//                 </div>

//                 {activeSection === 'customer' && (
//                     <div className="Costumer-information-section">

//                         <div className="left-section2">
//                             <div className="top-section">
//                                 <div className="title-top">
//                                     <span className='title'>Check Out Your Items</span>
//                                 </div>
//                                 <span className='text'>For a better experience, check your item and choose your shipping before ordering.</span>
//                             </div>

//                             <div className="bottom-section">
//                                 <div className="inputs-row">
//                                     <div className="input-wrapper">
//                                         <FaUser className="input-icon" />
//                                         <input type="text" placeholder="First Name" />
//                                     </div>
//                                     <div className="input-wrapper">
//                                         <FaUser className="input-icon" />
//                                         <input type="text" placeholder="Last Name" className='form-input' />
//                                     </div>
//                                 </div>

//                                 <textarea className='form-textarea' placeholder="177A Bleecker Street, New York City, NY 10012-1406, on the corner of Bleecker Street and Fenno Place in the heart of Greenwich Village."></textarea>
//                             </div>


//                         </div>

//                         <div className="left-section3">
//                             <div className="top-section">
//                                 <span className='title'>Payment Method</span>
//                             </div>
//                             <span className='text'>Select the bank for payment of your item</span>
//                             <div className="payment-card">
//                                 <div className='visa-cont'>
//                                     <img src="../../assets/visa-s1.png" alt="Visa" />
//                                     <div>
//                                         <span className='available'>Available to transfer all banks</span>
//                                     </div>
//                                 </div>
//                                 <div>
//                                     <input type="radio" name="payment" />
//                                 </div>
//                             </div>
//                             <div className="payment-card">
//                                 <div className="visa-cont">
//                                     <img src="../../assets/visa-s1.png" alt="Visa" />
//                                     <div>
//                                         <span className='available'>Available to transfer all banks</span>
//                                     </div>
//                                 </div>
//                                 <div>
//                                     <input type="radio" name="payment" />
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                 )}



//                 {activeSection === 'payment' && (
//                     <div className="Payment-details-section">
//                         <div className="left-section2">
//                             <div className="top-section">
//                                 <div className="title-top">
//                                     <span className='title'>Personal Details</span>
//                                 </div>
//                                 <span className='text'>Complete personal to continue the end of the payment</span>
//                             </div>
//                             <div className="bottom-section">
//                                 <div className="inputs-row">

//                                     <div className="input-wrapper">
//                                         <p className="input-title">First Name</p>

//                                         <input type="text" placeholder="Pikachu Chopechan" />

//                                     </div>
//                                     <div className="input-wrapper">
//                                         <p className="input-title">Email Address</p>
//                                         <input type="text" placeholder="Libya@gmail.com" />
//                                     </div>
//                                 </div>

//                                 <div className="input-wrapper">
//                                     <p className="input-title">Country</p>
//                                     <select placeholder="Libya">
//                                         <option value="Libya">Libya</option>
//                                         <option value="Egypt">Egypt</option>
//                                         <option value="Palestine">Palestine</option>
//                                     </select>
//                                 </div>


//                                 <div className="inputs-row">

//                                     <div className="input-wrapper">
//                                         <p className="input-title">State / Country</p>

//                                         <input type="text" placeholder="Libya" />

//                                     </div>
//                                     <div className="input-wrapper">
//                                         <p className="input-title">Postal Code</p>
//                                         <input type="text" placeholder="365423" />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="left-section3">
//                             <div>
//                                 <span>Billing Details</span>
//                             </div>
//                             <span className='text'>Complete the data from the payment card that will be used</span>


//                                 <div className="inputs-row">

//                                     <div className="input-wrapper">
//                                         <p className="input-title">Card Number</p>

//                                         <input type="text" placeholder="87346- 09374 - 28467 - 13892" />

//                                     </div>
                        
//                                 </div>

                                
//                          </div>
//                     </div>
//                 )}

//                 <div>
//                     <button className='checkout-button' onClick={goToCurrentOrder}>Checkout</button>
//                 </div>
//             </div>

//         </div>
//         </>
      
//     );
// };

// export default Payment;

import React, { useState } from 'react';
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { IoIosMail } from "react-icons/io";
import { FaCity } from "react-icons/fa";
import { FaCreditCard } from "react-icons/fa6";



import './Payment.css';

const Payment = () => {
    const navigate = useNavigate();

    const [countrySelected, setCountrySelected] = useState(false); 

    const [activeSection, setActiveSection] = useState('customer');
    const [isCustomerInfoComplete, setCustomerInfoComplete] = useState(false);

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    const handleCustomerInfoSubmit = () => {
        // Simulate customer info validation
        setCustomerInfoComplete(true);
        setActiveSection('payment');
    };


    // const goToCurrentOrder = () => {
    //     navigate('/CheckOut');
    // };


    const handleCountryChange = (e) => {
        if (e.target.value !== "") {
            setCountrySelected(true); // Set true when a country is selected
        } else {
            setCountrySelected(false); // Set false when no selection is made
        }
    };

    return (
        <>
        <div className="container">
            <div className='left-card-payment '>

                <div className="left-section1">
                    <span className={activeSection === 'customer' || isCustomerInfoComplete ? 'active' : ''} onClick={() => handleSectionClick('customer')}>
                        <FaCircleCheck className={isCustomerInfoComplete ? 'active-check-icon active' : 'active-check-icon'} /> Customer Information
                    </span>
                    <span className={activeSection === 'payment' ? 'active' : ''} onClick={() => handleSectionClick('payment')}>
                        <FaCircleCheck className={activeSection === 'payment' ? 'active-check-icon active' : 'active-check-icon'} /> Payment Details
                    </span>
                    <div className={isCustomerInfoComplete ? 'progress-line active' : 'progress-line'}></div>
                </div>

                {activeSection === 'customer' && (
                    <div className="Costumer-information-section">
                        <div className="left-section2">
                            <div className="top-section">
                                <div className="title-top">
                                    <span className='title'>Check Out Your Items</span>
                                </div>
                                <span className='text'>For a better experience, check your item and choose your shipping before ordering.</span>
                            </div>

                            <div className="bottom-section">
                                <div className="inputs-row">
                                    <div className="input-wrapper">
                                        <FaUser className="input-icon" />
                                        <input type="text" placeholder="First Name" />
                                    </div>
                                    <div className="input-wrapper">
                                        <FaUser className="input-icon" />
                                        <input type="text" placeholder="Last Name" className='form-input' />
                                    </div>
                                </div>
                                <textarea className='form-textarea' placeholder="Address"></textarea>
                            </div>
                        </div>

                        <div className="left-section3">
                            <div className="top-section">
                                <span className='title'>Payment Method</span>
                            </div>
                            <span className='text'>Select the bank for payment of your item</span>
                            <div className="payment-card">
                                <div className='visa-cont'>
                                    <img src="../../assets/visa-s1.png" alt="Visa" />
                                    <div>
                                        <span className='available'>Available to transfer all banks</span>
                                    </div>
                                </div>
                                <div>
                                    <input type="radio" name="payment" />
                                </div>
                            </div>
                            <div className="payment-card">
                                <div className="visa-cont">
                                    <img src="../../assets/visa-s1.png" alt="Visa" />
                                    <div>
                                        <span className='available'>Available to transfer all banks</span>
                                    </div>
                                </div>
                                <div>
                                    <input type="radio" name="payment" />
                                </div>
                            </div>
                        </div>

                        <button className='checkout-button continue-btn ' onClick={handleCustomerInfoSubmit}>Continue</button>
                    </div>
                )}

                {activeSection === 'payment' && (
                    <div className="Payment-details-section">
                        <div className="left-section2">
                            <div className="top-section">
                                <div className="title-top">
                                    <span className='title'>Personal Details</span>
                                </div>
                                <span className='text'>Complete personal to continue the end of the payment</span>
                            </div>
                            <div className="bottom-section">
                                <div className="inputs-row">
                                    <div className="input-wrapper">
                                        {/* <p className="input-title">First Name</p> */}
                                        <FaUser className="input-icon" />
                                        <input type="text" placeholder="Pikachu Chopechan" className='form-input'/>
                                    </div>
                                    
                                    <div className="input-wrapper">
                                        {/* <p className="input-title">Email Address</p> */}
                                        <IoIosMail  className="input-icon"/>
                                        <input type="text" placeholder="Libya@gmail.com" />
                                    </div>
                                </div>

                                <div className="input-wrapper full-width">
                                    <p className="input-title">Country</p>
                                    <select placeholder="Libya" 
                                    // className="country-select"
                                    className={`country-select ${countrySelected ? 'selected' : ''}`} 
                                    onChange={handleCountryChange}
                                    >
                                        <option value="Libya">Libya</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="Palestine">Palestine</option>
                                    </select>
                                </div>

                                <div className="inputs-row">
                                    <div className="input-wrapper">
                                        {/* <p className="input-title">State / Country</p> */}
                                        <FaCity className="input-icon" />
                                        <input type="text" placeholder="Libya" />
                                    </div>

                                    <div className="input-wrapper">
                                        {/* <p className="input-title">Postal Code</p> */}
                                        <input type="text" placeholder="365423" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="left-section3">
                            <div>
                                <span>Billing Details</span>
                            </div>
                            <span className='text'>Complete the data from the payment card that will be used</span>


                                <div className="inputs-row">

                                    <div className="input-wrapper">
                                        {/* <p className="input-title">Card Number</p> */}
                                        <FaCreditCard className="input-icon" />

                                        <input type="text" placeholder="87346- 09374 - 28467 - 13892" />

                                    </div>

                                    <div className='three-cards'>
                                        <div className='card-info'>
                                            <span>Expirated Card</span>
                                            <input type='text' placeholder='2026'/>
                                        </div>
                                        <div className='card-info'>
                                            <span>Security Code</span>
                                            <input type='text' placeholder='*******'/>
                                        </div>
                                        <div className='card-info'>
                                            <span className='third'>CVV</span>
                                            <input type='text' placeholder='3465'/>
                                        </div>
                                    </div>
                        
                                </div>

                                
                         </div>
                    </div>
                )}

                <div>
                    <button className='checkout-button' >Checkout</button>
                </div>
            </div>
        </div>
        </>
    );
};

export default Payment;
