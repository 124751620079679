import React, { useState } from "react";
import "./Contact.css";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import Nav from '../Filteration/Navigation/Nav';
import '../firebase';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import Footer from "../components/Main/Footer";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChatBotButton from "../components/Main/ChatBotButton";

const ContactForm = () => {
  const [inputvalue1, setInputvalue1] = useState('');
  const [inputvalue2, setInputvalue2] = useState('');
  const [inputvalue3, setInputvalue3] = useState('');
  const [inputvalue4, setInputvalue4] = useState('');

  const db = getFirestore();
  
  const setDataToFirestore = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contact-form"), {
        name: inputvalue1,
        email: inputvalue2,
        message: inputvalue3,
        phone: inputvalue4,
      });
      
      // Show success toast notification
      toast.success("Message sent successfully!", {
        // position: toast.POSITION.TOP_CENTER,
        autoClose: 3000, // Close after 3 seconds
        transition: Slide,
      });

      // Reset input fields
      setInputvalue1('');
      setInputvalue2('');
      setInputvalue3('');
      setInputvalue4('');
      
    } catch (error) {
      console.error("Error: ", error);
      
      // Show failure toast notification
      toast.error("Failed to send the message. Please try again.", {
        // position: toast.POSITION.TOP_CENTER,
        autoClose: 3000, // Close after 3 seconds
        transition: Slide,
      });
    }
  };

  const [focus, setFocus] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });

  const handleFocus = (inputName) => {
    setFocus((prevFocus) => ({ ...prevFocus, [inputName]: true }));
  };

  const handleBlur = (inputName, event) => {
    if (event.target.value === "") {
      setFocus((prevFocus) => ({ ...prevFocus, [inputName]: false }));
    }
  };

  return (
    <>
      <Nav />
      <div className="container">
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Let's get in touch</h3>
            <p className="text">Contact with us</p>

            <div className="info">
              <div className="information">
                <p>123 Street, New York</p>
              </div>
              <div className="information">
                <p>electro@gmail.com</p>
              </div>
              <div className="information">
                <p>123-456-789</p>
              </div>
            </div>

            <div className="social-media">
              <p>Connect with us :</p>
              <div className="social-icons">
                <a href="#"><FaFacebookF className="social-icons" /></a>
                <a href="#"><FaTwitter className="social-icons" /></a>
                <a href="#"><FaInstagram className="social-icons" /></a>
                <a href="#"><FaLinkedinIn className="social-icons" /></a>
              </div>
            </div>
          </div>

          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form autoComplete="off" method="Post" onSubmit={setDataToFirestore} className="main-form">
              <h3 className="title">Contact us</h3>

              <div className={`input-container ${focus.name ? "focus" : ""}`}>
                <input
                  type="text"
                  name="name"
                  className="input"
                  onFocus={() => handleFocus("name")}
                  onBlur={(e) => handleBlur("name", e)}
                  value={inputvalue1}
                  onChange={(e) => setInputvalue1(e.target.value)}
                  required
                />
                <label htmlFor="name">Username</label>
                <span>Username</span>
              </div>

              <div className={`input-container ${focus.email ? "focus" : ""}`}>
                <input
                  type="email"
                  name="email"
                  className="input"
                  onFocus={() => handleFocus("email")}
                  onBlur={(e) => handleBlur("email", e)}
                  value={inputvalue2}
                  onChange={(e) => setInputvalue2(e.target.value)}
                  required
                />
                <label htmlFor="email">Email</label>
                <span>Email</span>
              </div>

              <div className={`input-container ${focus.phone ? "focus" : ""}`}>
                <input
                  type="tel"
                  name="phone"
                  className="input"
                  onFocus={() => handleFocus("phone")}
                  onBlur={(e) => handleBlur("phone", e)}
                  value={inputvalue4}
                  onChange={(e) => setInputvalue4(e.target.value)}
                  required
                  pattern='^[1-9]\d*$'
                  minLength={10}
                />
                <label htmlFor="phone">Phone</label>
                <span>Phone</span>
              </div>

              <div className={`input-container textarea ${focus.message ? "focus" : ""}`}>
                <textarea
                  name="message"
                  className="input"
                  onFocus={() => handleFocus("message")}
                  onBlur={(e) => handleBlur("message", e)}
                  value={inputvalue3}
                  onChange={(e) => setInputvalue3(e.target.value)}
                  required

                ></textarea>
                <label htmlFor="message">Message</label>
                <span>Message</span>
              </div>

              <input type="submit" value="Send" className="btn" />
            </form>
          </div>
        </div>

        <ToastContainer />
      </div>

      <ChatBotButton />


      <Footer />
    </>
  );
};

export default ContactForm;
