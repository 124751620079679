import "./Colors.css";

const Colors = ({ handleChange }) => {
  return (
    <>
      <div className="color-container">
        <h2 className="sidebar-title color-title">By Color</h2>
        <div className="color-container">
          <label className="sidebar-label-container color-square label one">
            <input
              onChange={handleChange}
              type="radio"
              value="red"
              name="category"
              color="red"
              className="color-square "
            />
          </label>
          <label className="sidebar-label-container color-square label two">
            <input
              onChange={handleChange}
              type="radio"
              value="blue"
              name="category"
              color="blue"
              className="color-square"
            />
          </label>
          <label className="sidebar-label-container color-square label three">
            <input
              onChange={handleChange}
              type="radio"
              value="sky"
              name="category"
              color="sky"
              className="color-square"
            />
          </label>
          <label className="sidebar-label-container color-square label four">
            <input
              onChange={handleChange}
              type="radio"
              value="black"
              name="category"
              color="black"
              className="color-square"
            />
          </label>
          <label className="sidebar-label-container color-square label five">
            <input
              onChange={handleChange}
              type="radio"
              value="white"
              name="category"
              color="white"
              className="color-square"
            />
          </label>
          <label className="sidebar-label-container color-square label six">
            <input
              onChange={handleChange}
              type="radio"
              value="green"
              name="category"
              color="green"
              className="color-square"
            />
          </label>
          <label className="sidebar-label-container color-square label seven">
            <input
              onChange={handleChange}
              type="radio"
              value="black-green"
              name="category"
              color="black-green"
              className="color-square"
            />
          </label>
          <label className="sidebar-label-container color-square label eight">
            <input
              onChange={handleChange}
              type="radio"
              value="purple"
              name="category"
              color="purple"
              className="color-square"
            />
          </label>
          <label className="sidebar-label-container color-square label nine">
            <input
              onChange={handleChange}
              type="radio"
              value="yellow"
              name="category"
              color="yellow"
              className="color-square"
            />
          </label>
          <label className="sidebar-label-container color-square label ten">
            <input
              onChange={handleChange}
              type="radio"
              value="orange"
              name="category"
              color="orange"
              className="color-square"
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default Colors;
