import React, { createRef, useState } from 'react';
import './ChatBotButton.css';
import chatbot from '../../assets/chatbot.png';
import closeIcon from '../../assets/closebtn.png';
import { FaAngleLeft } from "react-icons/fa6";
import { IoTimeOutline } from "react-icons/io5";

const ChatBotButton = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [showEmojis, setShowEmojis] = useState(false);
    const [showQuestions, setShowQuestions] = useState(true);

    const inputRef = createRef();

    const questions = [
        "What is your name?",
        "How can I reach you?",
        "What are your working hours?"
    ];

    const answers = {
        "What is your name?": "My name is Hana!",
        "How can I reach you?": "lorem ipsum dolor sit amet consectetur!",
        "What are your working hours?": "We are available 24/7!"
    };

    const handleChatToggle = () => {
        setIsChatOpen(!isChatOpen);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSendMessage = () => {
        if (inputValue.trim() !== '') {
            const newMessage = {
                text: inputValue,
                time: new Date().toISOString(),
                status: 'sent',
                sender: 'user',
            };
            setMessages([...messages, newMessage]);
            setInputValue('');
            setShowQuestions(false);

            setTimeout(() => {
                const botResponseText = answers[inputValue] || "I'm sorry, I didn't understand that.";
                const botResponse = {
                    text: botResponseText,
                    time: new Date().toISOString(),
                    status: 'sent',
                    sender: 'bot',
                };
                setMessages(prevMessages => [...prevMessages, botResponse]);

                setTimeout(() => {
                    setShowQuestions(true);
                }, 2000);
            }, 1000);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const timeAgo = (timestamp) => {
        const now = new Date();
        const messageTime = new Date(timestamp);
        const secondsAgo = Math.floor((now - messageTime) / 1000);

        if (secondsAgo < 60) {
            return `${secondsAgo}s ago`;
        } else if (secondsAgo < 3600) {
            const minutesAgo = Math.floor(secondsAgo / 60);
            return `${minutesAgo}m ago`;
        } else if (secondsAgo < 86400) {
            const hoursAgo = Math.floor(secondsAgo / 3600);
            return `${hoursAgo}h ago`;
        } else {
            const daysAgo = Math.floor(secondsAgo / 86400);
            return `${daysAgo}d ago`;
        }
    };

    const handleShowEmojis = () => {
        setShowEmojis(!showEmojis);
    };

    const onEmojiClick = (event, emoji) => {
        setInputValue(inputValue + emoji);
        setShowEmojis(false);
    };

    const handleQuestionClick = (question) => {
        const userMessage = {
            text: question,
            time: new Date().toISOString(),
            status: 'sent',
            sender: 'user',
        };
        const botResponseText = answers[question];
        const botResponse = {
            text: botResponseText,
            time: new Date().toISOString(),
            status: 'sent',
            sender: 'bot',
        };

        setMessages(prevMessages => [...prevMessages, userMessage, botResponse]);
        setShowQuestions(false);

        setTimeout(() => {
            setShowQuestions(true);
        }, 2000);
    };

    return (
        <>
            <img
                src={isChatOpen ? closeIcon : chatbot}
                alt='chatbot'
                className="chatbot-button"
                onClick={handleChatToggle}
            />
            {isChatOpen && (
                <div className="chat-window">
                    <div className="chat-header">
                        <div>
                            <FaAngleLeft />
                        </div>
                        <div className='txt'>
                            <span>Bot</span>
                            <div className='time'>
                                <span className='time-icon'><IoTimeOutline /></span>
                                <span> A few minutes</span>
                            </div>
                        </div>
                    </div>
                    <div className="chat-body">
                        {messages.map((msg, index) => (
                            <div key={index} className="chat-message-container">
                                <div className={`chat-message ${msg.sender}`}>
                                    <p>{msg.text}</p>
                                </div>
                                {/* <span className="message-info">{timeAgo(msg.time)}</span> */}
                            </div>
                        ))}
                        {showQuestions && (
                            <div className="chat-questions">
                                {questions.map((question, index) => (
                                    <p className="chatbot-question" key={index} onClick={() => handleQuestionClick(question)}>
                                        {question}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ChatBotButton;
