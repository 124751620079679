import React, { useState , useEffect} from 'react';
import "./Price.css";;

const Price = ({ handlePriceChange }) => {
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    handlePriceChange(minPrice, maxPrice);
  };

  return (
    <div className="price-container">
      <h2 className="sidebar-title price-title">Price</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="number" 
          placeholder="Min" 
          value={minPrice} 
          onChange={(e) => setMinPrice(e.target.value)} 
        />
        <input 
          type="number" 
          placeholder="Max" 
          value={maxPrice} 
          onChange={(e) => setMaxPrice(e.target.value)} 
        />
        <button type="submit">Apply</button>
      </form>
    </div>
  );
};


export default Price;
