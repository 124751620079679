import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Header.css';
import headerImage1 from '../../assets/header2.png';
import headerImage2 from '../../assets/header2.png'; 

const Header = () => {
  const navigate = useNavigate();

  const goToProductsPage = () => {
    navigate('/products');
  };

  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
  };

  return (
    <header className="header-section">
      <div className="container">
        <Slider {...sliderSettings}>
          {/* Slide 1 */}
          <div className="header-slide">
            <div className={`header-container ${isResponsive ? 'column' : ''}`}>
              {isResponsive ? (
                <>
                  <div className="header-right">
                    <img src={headerImage1} alt="Header" className="header-image" />
                  </div>
                  <div className="header-left">
                    <h1>The New Collection <br /> of Summer Vacation</h1>
                    <p>
                      Elector Deals Hub mission is to provide passionate customers a dynamic,
                      ever-evolving, and passionate business environment.
                    </p>
                    <button className="header-button" onClick={goToProductsPage}>SHOP NOW</button>
                  </div>
                </>
              ) : (
                <>
                  <div className="header-left">
                    <h1>The New Collection <br /> of Summer Vacation</h1>
                    <p>
                      Elector Deals Hub mission is to provide passionate customers a dynamic,
                      ever-evolving, and passionate business environment.
                    </p>
                    <button className="header-button" onClick={goToProductsPage}>SHOP NOW</button>
                  </div>
                  <div className="header-right">
                    <img src={headerImage1} alt="Header" className="header-image" />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Slide 2 */}
          <div className="header-slide">
            <div className={`header-container ${isResponsive ? 'column' : ''}`}>
              {isResponsive ? (
                <>
                  <div className="header-right">
                    <img src={headerImage2} alt="Header" className="header-image" />
                  </div>
                  <div className="header-left">
                    <h1>Explore New Trends <br /> This Season</h1>
                    <p>
                      Discover the latest trends with Electro Deals Hub, your destination for quality.
                    </p>
                    <button className="header-button" onClick={goToProductsPage}>SHOP NOW</button>
                  </div>
                </>
              ) : (
                <>
                  <div className="header-left">
                    <h1>Explore New Trends <br /> This Season</h1>
                    <p>
                      Discover the latest trends with Electro Deals Hub, your destination for quality.
                    </p>
                    <button className="header-button" onClick={goToProductsPage}>SHOP NOW</button>
                  </div>
                  <div className="header-right">
                    <img src={headerImage2} alt="Header" className="header-image" />
                  </div>
                </>
              )}
            </div>
          </div>
        </Slider>
      </div>
    </header>
  );
};

export default Header;
