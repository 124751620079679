import React from 'react';
import './ReviewsSection.css';
import { FaStar, FaThumbsUp, FaReply, FaShare } from 'react-icons/fa';

const ReviewCard = ({ img, name, stars, comment }) => {
  return (

   

      <div className="review-card">
        <div className="review-card-top">
          <div className="user-img-container">
            <img src={img} alt={name} className="user-img" />
          </div>
          <div className="user-info">
            <h4>{name}</h4>
            <div className="stars">
              {[...Array(stars)].map((_, index) => (
                <FaStar key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="review-card-center">
          <p>{comment}</p>
        </div>
        <div className="review-card-bottom">
          <FaThumbsUp />
          <FaReply />
          <FaShare />
          
        </div>
      </div>

  

  );
};

const ReviewsSection = () => {
  const reviews = [
    {
      img: '../assets/cheerful-curly-man-with-bristle-points-left-wears-casual-clothes-spectacles 5 (1).png',
      name: 'Pablo Kahsandra',
      stars: 5,
      comment: 'The goods landed safely, arrived quickly, use instant delivery, the quality of the goods is okay and works well, the packing is safe and the delivery is fast, great, thank you!',
    },
    {
      img: '../assets/cheerful-curly-man-with-bristle-points-left-wears-casual-clothes-spectacles 5 (2).png',
      name: 'Tasya Mega',
      stars: 4,
      comment: 'The item is very good and works. Delivery by courier is very fast. Super safe packaging. Price according to quality',
    },
    {
      img: '../assets/cheerful-curly-man-with-bristle-points-left-wears-casual-clothes-spectacles 5.png',
      name: 'Samuel Drya',
      stars: 4,
      comment: 'The goods landed safely, arrived quickly, use instant delivery, the quality of the goods is okay and works well, the packing is safe and the delivery is fast, great, thank you!',
    },
    {
      img: '../assets/young-handsome-man-student-with-trendy-hairstyle-isolated 1.png',
      name: 'Michael Putri',
      stars: 5,
      comment: 'The goods landed safely, arrived quickly, use instant delivery, the quality of the goods is okay and works well, the packing is safe and the delivery is fast, great, thank you!',
    },
    {
      img: '../assets/serious-man-isolated-orange-background-confident-person-looking-camera.png',
      name: 'Mikasa Dum',
      stars: 3,
      comment: 'The goods landed safely, arrived quickly, use instant delivery, the quality of the goods is okay and works well, the packing is safe and the delivery is fast, great, thank you!',
    },
    {
      img: '../assets/cheerful-curly-man-with-bristle-points-left-wears-casual-clothes-spectacles 5 (2).png',
      name: 'Tasya Mega',
      stars: 5,
      comment: 'The goods landed safely, arrived quickly, use instant delivery, the quality of the goods is okay and works well, the packing is safe and the delivery is fast, great, thank you!',
    },
  ];

  return (
    <section className="reviews-section">
      <div className="reviews-column">

        {reviews.slice(0, 3).map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      {/* </div>
      
      <div className="reviews-column"> */}

        {reviews.slice(3).map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </div>
    </section>
  );
};

export default ReviewsSection;
