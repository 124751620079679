import React from 'react';
import Nav from '../Filteration/Navigation/Nav';
import Header from '../components/Main/Header';
import CardsSection from '../components/CardsSection';
import Slider from '../components/Slider';
import NewArrival from '../components/NewArrival';
import FeaturesCards from '../components/FeaturesCards';
import Footer from '../components/Main/Footer';
import ShopCards from '../components/ShopCards';
import ChatBotButton from '../components/Main/ChatBotButton';


const Home = ({ handleCategoryChange }) => {
  return (
    <div >
      <Nav onCategoryChange={handleCategoryChange} />
      <Header />
      <FeaturesCards />
      <CardsSection />
      <Slider />
      <ShopCards />
      <NewArrival />
      <ChatBotButton />
      <Footer />
    </div>
  );
};

export default Home;
