import React from 'react';
import { Link } from 'react-router-dom';
import { addToCart } from '../../components/Stores/Cart';
import { useDispatch } from 'react-redux';
import {  doc, setDoc, arrayUnion } from "firebase/firestore";
import { db } from "../../firebase";

import { useFavorites } from './FavoritesContext';
import { FaHeart, FaStar } from 'react-icons/fa';
import { IoCart } from "react-icons/io5";
import { useAuth } from '../../contexts/authContext';

import Nav from '../../Filteration/Navigation/Nav';
import Footer from '../Main/Footer';
import './Favorites.css';
import ChatBotButton from '../Main/ChatBotButton';

const Favorites = () => {
  const { favorites, removeFavorite } = useFavorites();

  const dispatch = useDispatch();

  const { currentUser } = useAuth(); // Retrieve current user from auth context


  const handleAddToCart = async (product) => {
    const productData = {
        productId: product.id,
        title: product.title || product[1],
        price: product.price || product[4],
        quantity: 1,
        img: product.imgUrl,
    };

    // Update Redux store
    dispatch(addToCart(productData));

    if (currentUser) {
        // User is logged in

        try {
            const userCartRef = doc(db, 'cart', currentUser.uid);
            await setDoc(userCartRef, {
                items: arrayUnion(productData)
            }, { merge: true });
        } catch (error) {
            console.error("Error adding to cart:", error);
        }

    } else {
        // User is a guest, store the cart in local storage
        let guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
        const existingProductIndex = guestCart.findIndex(item => item.productId === product.id);

        if (existingProductIndex >= 0) {
            guestCart[existingProductIndex].quantity += 1; // Update quantity if the item is already in the cart
        } else {
            guestCart.push(productData); // Add new product to the cart
        }

        localStorage.setItem('guestCart', JSON.stringify(guestCart));
    }
};

  return (
    <>
      <Nav />
      <div className="container">
        <div className="favorites-container">
          {favorites.length === 0 ? (
            <div className="empty-favorites">
              <p>No items in your favorites list.</p>
            </div>
          ) : (
            <div className="all-favorite-items">
              {/* {favorites.map((product) => (
                <div key={product.id} className="filter-productCard">
                  <div className="productImage-container">
                    <img src={product.img} alt={product.title} className="productImage" />
                    <FaHeart
                      className="productCard__heart"
                      style={{ color: 'red' }}
                      onClick={() => removeFavorite(product.id)}
                    />
                  </div>
                  <div className="productCard__content">
                    <h3 className="productName">{product.title}</h3>
                    <h3 className="productType">{product.productType}</h3>
                    <div className="card-price">
                      <div className="productPrice">
                        <del>{product.prevPrice}</del> {product.newPrice}
                      </div>
                      <div className="productRating">
                        {[...Array(product.reviews)].map((_, index) => (
                          <FaStar key={index} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}

              {favorites.map((product) => (
                  <div key={product.id} className="filter-productCard">
                      <div className="productImage-container">
                          <img src={product.imgUrl} alt={product.title} className="productImage" />
                          <FaHeart
                              className="productCard__heart"
                              style={{ color: 'red' }}
                              onClick={() => removeFavorite(product.id)}
                          />
                      </div>
                      <div className="productCard__content">
                          <h3 className="productName">{product.title}</h3>
                          <h3 className="productType">{product.category}</h3>
                          <div className="card-price">
                              <div className="productPrice">
                                  {product.basePrice}
                              </div>
                          </div>

                          <div className="productCard__buttons1">
                                        <button
                                            className="productCard__button productCard__button--purple1"
                                            onClick={() => handleAddToCart(product)}
                                        >
                                            <IoCart className='filter-cart-icon1' />
                                            Add To Cart
                                        </button>
                                        <Link to={`/product/${product.id}`}>
                                            <button className="productCard__button productCard__button--gray1">Quick View</button>
                                        </Link>

                                        
                                    </div>
                      </div>
                  </div>
              ))}

            </div>
          )}
        </div>
      </div>
      <ChatBotButton />

      <Footer />
    </>
  );
};

export default Favorites;