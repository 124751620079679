import React from 'react';
import './Product.css';

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";


function Pagination({ productsPerPage, totalProducts, currentPage, setCurrentPage }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination-container">
      <button
        className="pagination-arrow-icon"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <IoIosArrowBack />
      </button>
      {pageNumbers.map((page) => (
        <button
          key={page}
          className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      ))}
      <button
        className="pagination-arrow-icon"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === Math.ceil(totalProducts / productsPerPage)}
      >
        <IoIosArrowForward />
      </button>
    </div>
  );
}

export default Pagination;
