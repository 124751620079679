import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Products from './Pages/ProductsPage';
import ProductDetails from './Pages/ProductDetailsPage';
import CheckOut from './components/Payment/CheckOut';
import Payment from './components/Payment/Payment';
import ContactForm from './Pages/Contact';
import Login from './components/auth/login';
import ForgotPassword from './components/auth/PasswordLoginWithFirebase/ForgotPassword';
import Register from './components/auth/register';
// import AnimatedTooltipPreview from './components/AnimatedTooltipPreview';
// import Header from './components/header';
import { AuthProvider } from './contexts/authContext';
import { FavoritesProvider } from './components/MyCart/FavoritesContext';
import Favorites from './components/MyCart/Favorite';
import About from './Pages/About';
import ShoppingCart from './components/MyCart/ShoppingCart';
import Frequently from './Pages/Frequently';
import CustomerSupport from './Pages/CustomerSupport/CustomerSupport';
import PolicyPrivacy from './Pages/PolicyPrivacy';
// import ShoppingPayment from './components/MyCart/shoppingPayment';

function App() {
  return (
    <AuthProvider>
      <FavoritesProvider>
        <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/product/:id" element={<ProductDetails />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/checkout" element={<CheckOut />} />
              <Route path="/shopping_cart" element={<ShoppingCart />} />
              {/* <Route path="/shopping_payment" element={<ShoppingPayment />} /> */}
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ForgotPassword />} />
              <Route path="/favorites" element={<Favorites />} />
              <Route path="/frequently" element={<Frequently />} />
              <Route path="/customer-service" element={<CustomerSupport />} />
              <Route path="/policy" element={<PolicyPrivacy />} />
              {/* <Route path="/animation" element={<AnimatedTooltipPreview />} /> */}
              

            </Routes>
        </Router>
      </FavoritesProvider>
    </AuthProvider>
  );
}

export default App;
