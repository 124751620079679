import React, { useState } from "react";
import "./Rating.css";

const Rating = ({ onRatingChange }) => {
  const [selectedRatings, setSelectedRatings] = useState({});

  const handleRatingChange = (event) => {
    const { value, checked } = event.target;
    const ratings = value.split(',').map(Number); // Convert the value to an array of numbers

    setSelectedRatings((prevRatings) => {
      const updatedRatings = { ...prevRatings };

      if (checked) {
        ratings.forEach(rating => {
          updatedRatings[rating] = true;
        });
      } else {
        ratings.forEach(rating => {
          delete updatedRatings[rating];
        });
      }

      onRatingChange(updatedRatings); // Call the parent function with updated ratings
      return updatedRatings;
    });
  };

  return (
    <div className="rating-container">
      <h2>Rating</h2>
      <div className="rating-checkboxes">
        <label>
          <input
            type="checkbox"
            value="4,5"
            checked={Object.keys(selectedRatings).length > 0 && 
              Object.keys(selectedRatings).includes('4') && 
              Object.keys(selectedRatings).includes('5')}
            onChange={handleRatingChange}
          />
          (24-UP)
        </label>
        <label>
          <input
            type="checkbox"
            value="3"
            checked={selectedRatings[3] || false}
            onChange={handleRatingChange}
          />
          (8-24)
        </label>
        <label>
          <input
            type="checkbox"
            value="2"
            checked={selectedRatings[2] || false}
            onChange={handleRatingChange}
          />
          (0-8)
        </label>
        <label>
          <input
            type="checkbox"
            value="1"
            checked={selectedRatings[1] || false}
            onChange={handleRatingChange}
          />
          (0)
        </label>
      </div>
    </div>
  );
};

export default Rating;
