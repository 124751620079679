import React from 'react'
import Nav from '../Filteration/Navigation/Nav';

import ReviewsData from '../components/Reviews_ProductData/ReviewsData';
import ProductDetail from '../components/ProductDetail';
import Footer from '../components/Main/Footer';
import ChatBotButton from '../components/Main/ChatBotButton';

 const ProductDetailsPage = ()=> {
  return (
    <>
    <Nav />
    <ProductDetail />
    <ReviewsData />
    <ChatBotButton />

    <Footer />
    </>
)
}

export default ProductDetailsPage;
