// // the products of the filtration page

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// import { FaStar, FaHeart } from 'react-icons/fa';
// import { IoCart } from "react-icons/io5";
// import '../Filteration/Products/Product.css'
// import { useFavorites } from './MyCart/FavoritesContext';

// import { useDispatch } from 'react-redux';
// import { addToCart } from '../components/Stores/Cart';


// const ProductListing = ({ products }) => {


//   const [filters, setFilters] = useState({
//     category: '',
//     priceRange: [0, 1000],
//   });

//   const handleFilterChange = (newFilters) => {
//     setFilters(newFilters);
//   };

//   const filteredProducts = products.filter(product => {
//     return product.category.includes(filters.category) &&
//       product.price >= filters.priceRange[0] &&
//       product.price <= filters.priceRange[1];
//   });

//   return (
//     <div>
//       {/* <h2>Showing 1 - {filteredProducts.length} items out of {products.length} possible search results for {products.title}</h2> */}
//       {/* <Filters onChange={handleFilterChange} /> */}
//       <ProductList products={filteredProducts} />
//     </div>
//   );
// };

// const ProductList = ({ products }) => (
//   <div className="card-container-filtration">
//     {products.map(product => (
//       <Card key={product.id} product={product} />
//     ))}
//   </div>
// );

// const Filters = ({ onChange }) => {
//   const [category, setCategory] = useState('');
//   const [priceRange, setPriceRange] = useState([0, 1000]);

//   const handleCategoryChange = (e) => {
//     setCategory(e.target.value);
//     onChange({ category: e.target.value, priceRange });
//   };

//   const handlePriceChange = (newRange) => {
//     setPriceRange(newRange);
//     onChange({ category, priceRange: newRange });
//   };

//   return (
//     <div>
//       <input type="text" value={category} onChange={handleCategoryChange} placeholder="Category" />
//       <input type="range" min="0" max="1000" value={priceRange[0]} onChange={(e) => handlePriceChange([+e.target.value, priceRange[1]])} />
//       <input type="range" min="0" max="1000" value={priceRange[1]} onChange={(e) => handlePriceChange([priceRange[0], +e.target.value])} />
//     </div>
//   );
// };

// const Card = ({ img, title, productType, reviews, prevPrice, newPrice }) => {
//   const navigate = useNavigate();

//   const { addFavorite } = useFavorites();

//   const goToCheckOutPage = () => {
//     navigate('/payment', {
//       state: { img, title, productType, reviews, prevPrice, newPrice }
//     });
//   };

//   const handleAddFavorite = () => {
//     addFavorite({ img, title, productType, reviews, prevPrice, newPrice });
//   };

//   const dispatch = useDispatch();
//   const handleAddToCart = (product) => {
//     dispatch(addToCart({
//       productId: product.id,
//       quantity: 1
//     }));
//   };
//   const product = {
//     id: 1,
//     img: img,
//     title: title,
//     productType: productType,
//     reviews: reviews,
//     prevPrice: prevPrice,
//     newPrice: newPrice
//   };

//   return (
//     <div className='filter-productCard'>
//       <div className="productImage-container">
//         <img src={img} alt={title} className="productImage" />
//         <FaHeart className="productCard__heart" onClick={handleAddFavorite} />
//       </div>
//       <div className="productCard__content">
//         <h3 className="productName">{title}</h3>
//         <h3 className="productType">{productType}</h3>
//         <div className="card-price">
//           <div className="productPrice">
//             <del>{prevPrice}</del> {newPrice}
//           </div>
//           <div className="productRating">
//             {[...Array(reviews)].map((_, index) => (
//               <FaStar key={index} />
//             ))}
//           </div>
//         </div>
//         <div className="productCard__buttons">

//           <button
//             className="productCard__button productCard__button--purple1"
//             onClick={() => handleAddToCart(product)}
//           >
//             <span>
//               <IoCart className='filter-cart-icon1' />
//             </span>
//             <span>
//               Add To Cart
//             </span>
//           </button>

//           <button className="productCard__button productCard__button--gray" onClick={goToCheckOutPage}>
//             Buy Now
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Card;




import React , { useEffect, useState } from 'react';
import { FaHeart, FaStar } from 'react-icons/fa';
import { IoCart } from "react-icons/io5";
import { useFavorites } from './MyCart/FavoritesContext';
import { useDispatch } from 'react-redux';
import { addToCart } from '../components/Stores/Cart';
import { useNavigate } from 'react-router-dom';

const Card = ({ img, title, productType, star, reviews, prevPrice, newPrice }) => {

  const [Products, setProducts] = useState([])

  // Fetch Data 
  const GetProdects = async () => {
      const Res = await fetch(`http://localhost:3001/products`)
      const Data = await Res.json()
      // console.log(Data);
      setProducts(Data)
  }
  // handle Data Api
  useEffect(() => {
      GetProdects()
  }, [])


  const navigate = useNavigate();
  const { favorites, toggleFavorite } = useFavorites(); // Get context values

  const { addFavorite } = useFavorites();
  
  const dispatch = useDispatch();

  const goToCheckOutPage = () => {
    navigate('/payment', {
      state: { img, title, productType, reviews, prevPrice, newPrice }
    });
  };

  // const handleAddFavorite = () => {
  //   addFavorite({ img, title, productType, reviews, prevPrice, newPrice });
  // };

  // const addToFavProductsHandler =(key) =>{

  // }


  const handleAddToCart = (product) => {
    dispatch(addToCart({
      productId: product.id,
      quantity: 1
    }));
  };

  return (
    <div className='filter-productCard'>
      <div className="productImage-container">
        <img src={img} alt={title} className="productImage" />
        {/* <FaHeart className="productCard__heart" onClick={()=>{addToFavProductsHandler(product.key)}} /> */}
      </div>
      <div className="productCard__content">
        <h3 className="productName">{title}</h3>
        <h3 className="productType">{productType}</h3>
        <div className="card-price">
          <div className="productPrice">
            <del>{prevPrice}</del> {newPrice}
          </div>
          <div className="productRating">
            {[...Array(star)].map((_, index) => (
              <FaStar key={index} />
            ))}
          </div>
        </div>
        <div className="productCard__buttons">
          <button
            className="productCard__button productCard__button--purple1"
            // onClick={handleAddToCart}
            onClick={() => handleAddToCart({ img, title, productType, newPrice })}

          >
            <span>
              <IoCart className='filter-cart-icon1' />
            </span>
            Add To Cart
          </button>
          <button className="productCard__button productCard__button--gray" onClick={goToCheckOutPage}>
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;

