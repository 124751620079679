import { sendPasswordResetEmail } from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from '../../../firebase'; 
// import './ForgotPassword.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ForgotPassword() {
    const navigate = useNavigate();

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const emailVal = e.target.email.value;
    //     try {
    //         await sendPasswordResetEmail(auth, emailVal);
    //         alert("Check your email to reset your password.");
    //         // navigate("/");
    //     } catch (err) {
    //         alert(err.code);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailVal = e.target.email.value;
        try {
            await sendPasswordResetEmail(auth, emailVal);
            toast.success("Check your email to reset your password.", {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            // navigate("/");
        } catch (err) {
            toast.error(`Error: ${err.code}`, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };
    return (
        <main className="login-marginTop w-full height-full flex self-center place-content-center place-items-center">
                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="forgot-password-card">
                        <p className="lock-icon"><i className="fas fa-lock"></i></p>
                        <h2 className='text-center'>Forgot Password?</h2>
                        <p className='text-center mt-2'>You can reset your Password here</p>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="email"
                                className="w-full mt-4 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                                placeholder="Email address"
                                required
                            />
                            <button type="submit"
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-[#8139A1] hover:bg-[#8139A1] hover:shadow-xl transition duration-300 mt-4'>Send</button>
                        </form>
                        <ToastContainer />
                    </div>
                </div>
        </main>
    );
}

export default ForgotPassword;
