import React from "react";
import Slider from "react-slick";
import "./ShopCards.css";

import Handphone from '../assets/phone.png';
import Camera from '../assets/camera.png';
import Hardware from '../assets/hardware.png';
import Audios from '../assets/audio.png';
import Watch from '../assets/watch.png';
import Gaming from '../assets/gaming.png';

const ShopCards = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <div className="container">
            <div className="shop-card-container">
                <div className="top-div">
                    <h2 className="shop-card-title">Find Products by Category</h2>
                </div>
                <div className="bottom-div">
                    <Slider {...settings}>
                        {[
                            { img: Handphone, title: "Handphone", items: "2.3k items" },
                            { img: Camera, title: "Camera", items: "2.3k items" },
                            { img: Hardware, title: "Hardware", items: "2.3k items" },
                            { img: Audios, title: "Audios", items: "2.3k items" },
                            { img: Watch, title: "Smart Watch", items: "2.3k items" },
                            { img: Gaming, title: "Gaming", items: "2.3k items" }
                        ].map((product, index) => (
                            <div key={index} className="card">
                                <div className="image-container">
                                    <img src={product.img} alt={product.title} className="card-image" />
                                </div>
                                <h3 className="card-title">{product.title}</h3>
                                <p className="card-description">{product.items}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default ShopCards;
