// import React, { useState } from 'react';
// import './SupportContent.css';

// const supportSubjects = [
//   { id: 1,image: '../../assets/', title: 'Account Issues', content: ' Content 1' },
//   { id: 2, image: '../../assets/', title: 'Payment Problems', content: 'Content 2' },
//   { id: 3, image: '../../assets/', title: 'Technical Support', content: 'Content 3' },
// ];

// const SupportContent = () => {
//   const [filteredSubjects, setFilteredSubjects] = useState(supportSubjects);

//   const handleSearch = (searchTerm) => {
//     const filtered = supportSubjects.filter((subject) =>
//       subject.title.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredSubjects(filtered);
//   };

//   return (
//     <div className="main_body ">
//       <div className="container ">
//         <div className="support-content">
//           {/* Left Div */}
//           <div className="left-content">
//             <h2 className="left-title">Find your answer by subject</h2>
//             <div className="title-border"></div>

//             <div className="left-cards">
//               {filteredSubjects.length > 0 ? (
//                 filteredSubjects.map((subject) => (

//                   <div key={subject.id} className="card">
//                     {subject.image && <img src={subject.image} />}
//                     {subject.title}
                    
//                    <p>{subject.content}</p> 
//                   </div>
//                 ))
//               ) : (
//                 <p>No subjects found.</p>
//               )}
//             </div>
//           </div>
//         </div>

//         <div className="not_found">
//           <div className="not_found_inner">
//             <h2>Didn’t find an answer to your question?</h2>
//             <p>Please reach out to our support team</p>
//             <button className="support_btn">Contact Support</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SupportContent;
import React from 'react';
import './SupportContent.css';
import { useNavigate } from 'react-router-dom';

const supportSubjects = [
  { id: 1, image: '../../assets/account.png', title: 'Account Issues', content: 'Content 1' },
  { id: 2, image: '../../assets/payment.png', title: 'Payment Problems', content: 'Content 2' },
  { id: 3, image: '../../assets/technical.png', title: 'Technical Support', content: 'Content 3' },
];

const SupportContent = ({ searchTerm }) => {
  const filteredSubjects = supportSubjects.filter((subject) =>
    subject.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const navigate = useNavigate();

  const goToContact = () => {
    navigate('/contact');
  }

  return (
    <div className="main_body">
      <div className="container">
        <div className="support-content">
          <div className="left-content">
            <h2 className="left-title">Find your answer by subject</h2>
            <div className="title-border"></div>

            <div className="left-cards">
              {filteredSubjects.length > 0 ? (
                filteredSubjects.map((subject) => (
                  <div key={subject.id} className="card">
                    {subject.image && <img src={subject.image} alt={subject.title} />}
                    <h3>{subject.title}</h3>
                    <p>{subject.content}</p>
                  </div>
                ))
              ) : (
                <p>No subjects found.</p>
              )}
            </div>
          </div>
        </div>

        <div className="not_found">
          <div className="not_found_inner">
            <h2>Didn’t find an answer to your question?</h2>
            <p>Please reach out to our support team</p>
            <button className="support_btn" onClick={() => goToContact()}>Contact</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportContent;
