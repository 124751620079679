import React from 'react';
import './PolicyPrivacy.css';
import Nav from '../Filteration/Navigation/Nav';

import Footer from '../components/Main/Footer';
import ChatBotButton from '../components/Main/ChatBotButton';

const PolicyPrivacy = () => {
  return (
    <>
    <Nav />
    
    <div class="policy_header">
    <div className='container '>
        <div className="header_txt">
        <h1>Privacy & Policy</h1>
        <p>Last modified: October 26, 2024</p>
        </div>

   
    </div>
    </div>
    <div className='container '>
    <div class="content-container">
        <h2 class="section-title">Introduction</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et purus euismod, luctus lectus a, facilisis ipsum.</p>

        <h2 class="section-title">Using our Services</h2>
        <p>Vestibulum vehicula libero nec dui pretium, et ullamcorper est tincidunt. Phasellus consequat lorem nec est elementum, nec tempus nunc finibus.</p>

        <h2 class="section-title">Privacy and Copyright Protection</h2>
        <p>Nulla facilisi. Vivamus euismod diam et dictum efficitur. Donec malesuada ante a varius porttitor.</p>

 </div>
    </div>
    <ChatBotButton />

    <Footer />
    </>
   
  );
};

export default PolicyPrivacy;
