// import React from 'react';
// import './FeaturesCards.css';
// import freeDelivery from '../assets/freeDelivery.png';
// import customerService from '../assets/freeDelivery.png';
// import money from '../assets/money.png';
// const FeaturesCards = () => {
//   return (
//       <div className="container">
//         <div className="card-section">

//           <div className="card">
//             <img src={freeDelivery} alt="service" />
//             <h2> FREE AND FAST DELIVERY</h2>
//             <p>Free delivery for all orders over $140</p>
//           </div>

//           <div className="card">
//             <img src={customerService} alt="service" />
//             <h2> 24/7 CUSTOMER SERVICE</h2>
//             <p>Friendly 24/7 customer support</p>
//           </div>

//           <div className="card">
//             <img src={money} alt="service" />
//             <h2>MONEY BACK GUARANTEE</h2>
//             <p>We reurn money within 30 days</p>
//           </div>

//         </div>
//       </div>
    
//   );
// }

// export default FeaturesCards;
import React from 'react';
import './FeaturesCards.css';
import freeDelivery from '../assets/freeDelivery.png';
import customerService from '../assets/freeDelivery.png';
import money from '../assets/money.png';

const FeaturesCards = () => {
  return (
    <div className="container">
      <div className="card-section animate-cards"> {/* Added class for animation */}
        <div className="card">
          <img src={freeDelivery} alt="service" />
          <h2> FREE AND FAST DELIVERY</h2>
          <p>Free delivery for all orders over $140</p>
        </div>

        <div className="card">
          <img src={customerService} alt="service" />
          <h2> 24/7 CUSTOMER SERVICE</h2>
          <p>Friendly 24/7 customer support</p>
        </div>

        <div className="card">
          <img src={money} alt="service" />
          <h2>MONEY BACK GUARANTEE</h2>
          <p>We return money within 30 days</p>
        </div>
      </div>
    </div>
  );
}

export default FeaturesCards;
