// // import React, { createContext, useState, useContext, useEffect } from 'react';

// // const FavoritesContext = createContext();

// // export const useFavorites = () => useContext(FavoritesContext);

// // export const FavoritesProvider = ({ children }) => {
// //     const [favorites, setFavorites] = useState(() => {
// //         const savedFavorites = localStorage.getItem('favorites');
// //         return savedFavorites ? JSON.parse(savedFavorites) : [];
// //     });

// //     useEffect(() => {
// //         localStorage.setItem('favorites', JSON.stringify(favorites));
// //     }, [favorites]);

// //     //   const addFavorite = (product) => {
// //     //     setFavorites((prevFavorites) => [...prevFavorites, product]);
// //     //   };

// //     const addFavorite = (product) => {
// //         setFavorites((prevFavorites) => {
// //             const isAlreadyFavorite = prevFavorites.some(item => item.id === product.id);
// //             if (isAlreadyFavorite) {
// //                 return prevFavorites;
// //                 //  return prevFavorites.filter((item) => item.id === product.id);
// //             } else {
// //                 return [...prevFavorites, product];
// //             }
// //         });
// //     };

// //     const removeFavorite = (productId) => {
// //         setFavorites((prevFavorites) => prevFavorites.filter((item) => item.id !== productId));
// //     };

// //     return (
// //         <FavoritesContext.Provider value={{ favorites, addFavorite, removeFavorite }}>
// //             {children}
// //         </FavoritesContext.Provider>
// //     );
// // };


// import React, { createContext, useState, useContext, useEffect } from 'react';

// const FavoritesContext = createContext();

// export const useFavorites = () => useContext(FavoritesContext);

// export const FavoritesProvider = ({ children, user }) => {
//     const userId = user?.id || 'guest'; // Fallback to 'guest' if no user is logged in
//     const [favorites, setFavorites] = useState(() => {
//         const savedFavorites = JSON.parse(localStorage.getItem(userId)) || [];
//         return savedFavorites;
//     });

//     useEffect(() => {
//         localStorage.setItem(userId, JSON.stringify(favorites));
//     }, [favorites, userId]);

//     const addFavorite = (product) => {
//         setFavorites((prevFavorites) => {
//             const isAlreadyFavorite = prevFavorites.some(item => item.id === product.id);
//             if (isAlreadyFavorite) {
//                 return prevFavorites; // No change if already a favorite
//             } else {
//                 return [...prevFavorites, product];
//             }
//         });
//     };

//     const removeFavorite = (productId) => {
//         setFavorites((prevFavorites) => prevFavorites.filter(item => item.id !== productId));
//     };

//     // const toggleFavorite = (product) => {
//     //     const isAlreadyFavorite = favorites.some(item => item.id === product.id);
//     //     if (isAlreadyFavorite) {
//     //         removeFavorite(product.id);
//     //     } else {
//     //         addFavorite(product);
//     //     }
//     // };

//     const toggleFavorite = (product) => {
//         const isAlreadyFavorite = favorites.some(item => item.id === product.id);
//         if (isAlreadyFavorite) {
//             removeFavorite(product.id);
//         } else {
//             addFavorite(product);
//         }
//     };
    

//     return (
//         <FavoritesContext.Provider value={{ favorites, addFavorite, removeFavorite, toggleFavorite }}>
//             {children}
//         </FavoritesContext.Provider>
//     );
// };



// import React, { createContext, useContext, useEffect, useState } from "react";
// import { doc, setDoc, onSnapshot } from "firebase/firestore";
// import { db } from "../../firebase"; 

// const FavoritesContext = createContext();

// export const useFavorites = () => useContext(FavoritesContext);

// export const FavoritesProvider = ({ children }) => {
//   const [favorites, setFavorites] = useState([]);
//   const [currentUser, setCurrentUser] = useState(null);

//   useEffect(() => {
//     // Simulating user authentication
//     const simulatedUser = { uid: "uid" }; // Replace with actual authentication logic
//     setCurrentUser(simulatedUser);
//   }, []);

//   useEffect(() => {
//     if (currentUser) {
//       // Listen to changes in Firestore
//       const unsubscribe = onSnapshot(doc(db, "favorites", currentUser.uid), (doc) => {
//         if (doc.exists()) {
//           setFavorites(doc.data().favorites || []);
//         } else {
//           setFavorites([]);
//         }
//       });

//       return () => unsubscribe(); // Cleanup on unmount
//     }
//   }, [currentUser]);

//   const addFavorite = (product) => {
//     // Validate that the necessary fields are defined
//     if (!product.id || !product.title || !product.img) {
//       console.error("Product is missing required fields:", product);
//       return;
//     }

//     setFavorites((prevFavorites) => {
//       const isAlreadyFavorite = prevFavorites.some((item) => item.id === product.id);
//       if (isAlreadyFavorite) {
//         return prevFavorites; // Prevent duplicates
//       } else {
//         return [...prevFavorites, product];
//       }
//     });
//   };

//   const removeFavorite = (productId) => {
//     setFavorites((prevFavorites) =>
//       prevFavorites.filter((item) => item.id !== productId)
//     );
//   };

//   useEffect(() => {
//     const saveFavorites = async () => {
//       if (currentUser) {
//         // Filter out any favorites with undefined fields before saving
//         const validFavorites = favorites.filter((fav) => fav && fav.id && fav.title && fav.img);

//         const userFavoritesRef = doc(db, "favorites", currentUser.uid);
//         await setDoc(userFavoritesRef, { favorites: validFavorites }, { merge: true });
//         localStorage.setItem(`favorites_${currentUser.uid}`, JSON.stringify(validFavorites));
//       } else {
//         // Save to local storage for guest users
//         localStorage.setItem("guest_favorites", JSON.stringify(favorites));
//       }
//     };

//     if (favorites.length > 0) {
//       saveFavorites();
//     }
//   }, [favorites, currentUser]);


// const toggleFavorite = (product) => {
//     // to add or remove a favorite
//     if (favorites.some((item) => item.id === product.id)) {
//       removeFavorite(product.id);
//     } else {
//       addFavorite(product);
//     }
//   };


//   return (
//     <FavoritesContext.Provider value={{ favorites, addFavorite, removeFavorite, toggleFavorite }}>
//       {children}
//     </FavoritesContext.Provider>
//   );
// };



// import React, { createContext, useContext, useEffect, useState } from "react";
// import { doc, setDoc, onSnapshot } from "firebase/firestore";
// import { getAuth, onAuthStateChanged } from "firebase/auth"; // Firebase auth import
// import { db } from "../../firebase"; 

// const FavoritesContext = createContext();

// export const useFavorites = () => useContext(FavoritesContext);

// export const FavoritesProvider = ({ children }) => {
//   const [favorites, setFavorites] = useState([]);
//   const [currentUser, setCurrentUser] = useState(null);

//   useEffect(() => {
//     const auth = getAuth();

//     // Listen for authentication state changes
//     const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setCurrentUser(user);
//       } else {
//         setCurrentUser(null);
//       }
//     });

//     return () => unsubscribeAuth(); // Cleanup on unmount
//   }, []);

//   useEffect(() => {
//     if (currentUser) {
//       // Listen to changes in Firestore for the logged-in user's favorites
//       const unsubscribeFavorites = onSnapshot(doc(db, "favorites", currentUser.uid), (doc) => {
//         if (doc.exists()) {
//           setFavorites(doc.data().favorites || []);
//         } else {
//           setFavorites([]);
//         }
//       });

//       return () => unsubscribeFavorites(); // Cleanup on unmount
//     }
//   }, [currentUser]);

//   const addFavorite = (product) => {
//     if (!product.id || !product.title || !product.img) {
//       console.error("Product is missing required fields:", product);
//       return;
//     }

//     setFavorites((prevFavorites) => {
//       const isAlreadyFavorite = prevFavorites.some((item) => item.id === product.id);
//       if (isAlreadyFavorite) {
//         return prevFavorites;
//       } else {
//         return [...prevFavorites, product];
//       }
//     });
//   };

//   const removeFavorite = (productId) => {
//     setFavorites((prevFavorites) =>
//       prevFavorites.filter((item) => item.id !== productId)
//     );
//   };

//   useEffect(() => {
//     const saveFavorites = async () => {
//       if (currentUser) {
//         const validFavorites = favorites.filter((fav) => fav && fav.id && fav.title && fav.img);

//         const userFavoritesRef = doc(db, "favorites", currentUser.uid);

//         // 
//         try {
//           await setDoc(userFavoritesRef, { favorites: validFavorites }, { merge: true });
//           localStorage.setItem(`favorites_${currentUser.uid}`, JSON.stringify(validFavorites));
//         } catch (error) {
//           console.error("Error saving favorites to Firestore:", error);
//         }
//       } else {
//         // Save to local storage for guest users
//         localStorage.setItem("guest_favorites", JSON.stringify(favorites));
//       }
//     };

//     if (favorites.length > 0) {
//       saveFavorites();
//     }
//   }, [favorites, currentUser]);

//   const toggleFavorite = (product) => {
//     if (!product || !product.id || !product.title || !product.img) {
//       console.error("Invalid product data:", product);
//       return;
//     }

//     if (favorites.some((item) => item.id === product.id)) {
//       removeFavorite(product.id);
//     } else {
//       addFavorite(product);
//     }
//   };

//   return (
//     <FavoritesContext.Provider value={{ favorites, addFavorite, removeFavorite, toggleFavorite }}>
//       {children}
//     </FavoritesContext.Provider>
//   );
// };

// 1-10

// import React, { createContext, useContext, useEffect, useState } from "react";
// import { collection, query, where, onSnapshot, setDoc, doc, deleteDoc } from "firebase/firestore";
// import { getAuth, onAuthStateChanged } from "firebase/auth"; 
// import { db } from "../../firebase"; 

// const FavoritesContext = createContext();

// export const useFavorites = () => useContext(FavoritesContext);

// export const FavoritesProvider = ({ children }) => {
//   const [favorites, setFavorites] = useState([]);
//   const [currentUser, setCurrentUser] = useState(null);

//   // Listen for authentication state changes
//   useEffect(() => {
//     const auth = getAuth();

//     const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setCurrentUser(user);
//       } else {
//         setCurrentUser(null);
//         setFavorites([]); // Clear favorites on logout
//       }
//     });

//     return () => unsubscribeAuth(); // Cleanup on unmount
//   }, []);

//   // Fetch user's favorite items from Firestore when the user is authenticated
//   useEffect(() => {
//     if (currentUser) {
//       const favoritesRef = collection(db, "favorites");
//       const q = query(favoritesRef, where("userId", "==", currentUser.uid));

//       // Listen for changes in the user's favorites collection
//       const unsubscribeFavorites = onSnapshot(q, (snapshot) => {
//         const favoritesData = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setFavorites(favoritesData);
//       });

//       return () => unsubscribeFavorites(); // Cleanup on unmount
//     }
//   }, [currentUser]);

//   // Add favorite as a separate document in Firestore
//   const addFavorite = async (product) => {
//     if (!currentUser || !product.id || !product.title || !product.img) {
//       console.error("Product is missing required fields or user is not authenticated:", product);
//       return;
//     }

//     const favoriteDocRef = doc(db, "favorites", `${currentUser.uid}_${product.id}`);
//     try {
//       await setDoc(favoriteDocRef, {
//         userId: currentUser.uid,
//         ...product,
//       });
//     } catch (error) {
//       console.error("Error adding favorite: ", error);
//     }
//   };

//   // Remove favorite by deleting the document in Firestore
//   const removeFavorite = async (productId) => {
//     if (!currentUser) return;

//     const favoriteDocRef = doc(db, "favorites", `${currentUser.uid}_${productId}`);
//     try {
//       await deleteDoc(favoriteDocRef);
//     } catch (error) {
//       console.error("Error removing favorite: ", error);
//     }
//   };

//   // Toggle favorite (add/remove)
//   const toggleFavorite = (product) => {
//     if (favorites.some((item) => item.id === product.id)) {
//       removeFavorite(product.id);
//     } else {
//       addFavorite(product);
//     }
//   };

//   return (
//     <FavoritesContext.Provider value={{ favorites, addFavorite, removeFavorite, toggleFavorite }}>
//       {children}
//     </FavoritesContext.Provider>
//   );
// };


import React, { createContext, useContext, useEffect, useState } from "react";
import { collection, query, where, onSnapshot, setDoc, doc, deleteDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth"; 
import { db } from "../../firebase"; 

const FavoritesContext = createContext();

export const useFavorites = () => useContext(FavoritesContext);

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  // Listen for authentication state changes
  useEffect(() => {
    const auth = getAuth();

    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
        const guestFavorites = JSON.parse(localStorage.getItem("guest_favorites")) || [];
        setFavorites(guestFavorites); // Load guest favorites from localStorage
      }
    });

    return () => unsubscribeAuth(); // Cleanup on unmount
  }, []);

  // Fetch user's favorite items from Firestore when the user is authenticated
  useEffect(() => {
    if (currentUser) {
      const favoritesRef = collection(db, "favorites");
      const q = query(favoritesRef, where("userId", "==", currentUser.uid));

      // Listen for changes in the user's favorites collection
      const unsubscribeFavorites = onSnapshot(q, (snapshot) => {
        const favoritesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFavorites(favoritesData);
      });

      return () => unsubscribeFavorites(); // Cleanup on unmount
    }
  }, [currentUser]);

  // Add favorite
  // const addFavorite = async (product) => {
  //   if (!product.id || !product.title || !product.img) {
  //     console.error("Product is missing required fields:", product);
  //     return;
  //   }
  const addFavorite = async(product) =>{
  const requiredFields = ['id', 'title', 'category','description', 'imgUrl', 'basePrice', 'discountPrice'];
  
  const missingFields = requiredFields.filter(field => !product[field]);
  
  if (missingFields.length > 0) {
    console.error(`Product is missing required fields: ${missingFields.join(', ')}`);
    return; // Prevent adding product if fields are missing
  }
  
  // Continue with adding the product
  // ...
// }


    if (currentUser) {
      // Authenticated user: Add to Firestore
      const favoriteDocRef = doc(db, "favorites", `${currentUser.uid}_${product.id}`);
      try {
        await setDoc(favoriteDocRef, {
          userId: currentUser.uid,
          ...product,
        });
      } catch (error) {
        console.error("Error adding favorite: ", error);
      }
    } else {
      // Guest user: Save to localStorage
      setFavorites((prevFavorites) => {
        const isAlreadyFavorite = prevFavorites.some((item) => item.id === product.id);
        if (!isAlreadyFavorite) {
          const updatedFavorites = [...prevFavorites, product];
          localStorage.setItem("guest_favorites", JSON.stringify(updatedFavorites));
          return updatedFavorites;
        }
        return prevFavorites;
      });
    }
  };

  // Remove favorite
  const removeFavorite = async (productId) => {
    if (currentUser) {
      // Authenticated user: Remove from Firestore
      const favoriteDocRef = doc(db, "favorites", `${currentUser.uid}_${productId}`);
      try {
        await deleteDoc(favoriteDocRef);
      } catch (error) {
        console.error("Error removing favorite: ", error);
      }
    } else {
      // Guest user: Remove from localStorage
      setFavorites((prevFavorites) => {
        const updatedFavorites = prevFavorites.filter((item) => item.id !== productId);
        localStorage.setItem("guest_favorites", JSON.stringify(updatedFavorites));
        return updatedFavorites;
      });
    }
  };

  // Toggle favorite (add/remove)
  // const toggleFavorite = (product) => {
  //   if (favorites.some((item) => item.id === product.id)) {
  //     removeFavorite(product.id);
  //   } else {
  //     addFavorite(product);
  //   }
  // };
  const toggleFavorite = (product) => {
    const isFavorite = favorites.some((item) => item.id === product.id);
    
    if (isFavorite) {
      removeFavorite(product.id);
    } else {
      addFavorite(product);
    }

    // if (!product.id || !product.title || !product.imgUrl) {
    //   console.error('Product is missing required fields:', product);
    //   return;
    // }
    // const missingFields = [];
    // if (!product.id) missingFields.push('id');
    // if (!product.title) missingFields.push('title');
    // if (!product.imgUrl) missingFields.push('imgUrl');
    // if (missingFields.length > 0) {
    //   console.error('Product is missing required fields:', missingFields, product);
    //   return;
    // }
        
  };
  // console.log(favorites);
  

  return (
    <FavoritesContext.Provider value={{ favorites, addFavorite, removeFavorite, toggleFavorite }}>
      {children}
    </FavoritesContext.Provider>
  );
};
