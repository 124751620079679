// data for filtration page
const data = [
  {
    id: 1,
    img: "/assets/image 13.png",
    title: "Camera",
    productType: "Mirrorless Camera | Nikon",
    star: 2,
    newPrice: "1725",
    company: "Camera",
    color: "black",
    category: "Camera",
    slug: "mirrorless-camera-nikon",
  },
  {
    id: 2,
    img: "/assets/image 10.png",
    title: "Smart Watch",
    productType: "Beats by Dre C 3450",
    star: 5,
    newPrice: "400",
    company: "Watch",
    color: "black-green",
    category: "Smart Watch",
    slug: "beats-by-dre-c-3450",
  },
  {
    id: 3,
    img: "/assets/image 11.png",
    title: "Handphone",
    productType: "iPhone XS Max Pro",
    star: 2,
    newPrice: "500",
    company: "Handphone",
    color: "white",
    category: "Handphone",
    slug: "iphone-xs-max-pro",
  },
  {
    id: 4,
    img: "/assets/image 12.png",
    title: "Audios",
    productType: "Apple Watch 4 2020",
    star: 3,
    newPrice: "200",
    company: "Audios",
    color: "black",
    category: "Audios",
    slug: "apple-watch-4-2020",
  },
  {
    id: 5,
    img: "/assets/image 12.png",
    title: "Audios",
    productType: "Airpods 2nd Gen",
    star: 5,
    newPrice: "200",
    company: "Audios",
    color: "black",
    category: "Audios",
    slug: "airpods-2nd-gen",
  },
  {
    id: 6,
    img: "/assets/image 7.png",
    title: "Smart Watch",
    productType: "Samsung Galaxy Watch 3",
    // star: <AiFillStar className="rating-star" />,
    star: 1,
    newPrice: "400",
    company: "Watch",
    color: "black",
    category: "Smart Watch",
    slug: "samsung-galaxy-watch-3",
  },
  {
    id: 7,
    img: "/assets/image 6.png",
    title: "Hardware",
    productType: "Neve Strix Pro L123...",
    star: 5,
    newPrice: "300",
    company: "Hardware",
    color: "black",
    category: "Hardware",
    slug: "neve-strix-pro-l123",
  },
  {
    id: 8,
    img: "/assets/image 5.png",
    title: "Audios",
    productType: "Harman Kardon Speaker",
    star: 4,
    newPrice: "200",
    company: "Audios",
    color: "black",
    category: "Audios",
    slug: "harman-kardon-speaker",
  },
  {
    id: 9,
    img: "/assets/image 12.png",
    title: "Audios",
    productType: "Apple Watch 4 2020",
    star: 5,
    newPrice: "200",
    company: "Audios",
    color: "black",
    category: "Audios",
    slug: "apple-watch-4-2020",
  },
  {
    id: 10,
    img: "/assets/image 10.png",
    title: "Smart Watch",
    productType: "Beats by Dre C 3450",
    star: 5,
    newPrice: "400",
    company: "Watch",
    color: "black",
    category: "Smart Watch",
    slug: "beats-by-dre-c-3450",
  },
  
  {
    id: 11,
    img: "/assets/image 13.png",
    title: "Camera",
    productType: "Mirrorless Camera | Nikon",
    star: 4,
    newPrice: "1725",
    company: "Camera",
    color: "black",
    category: "Camera",
    slug: "mirrorless-camera-nikon",
  },

];

export default data;
