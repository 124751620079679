import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaStar, FaHeart } from 'react-icons/fa';
import { IoCart, IoArrowForward } from "react-icons/io5";
import './CardsSection.css';
import { useFavorites } from './MyCart/FavoritesContext';
import { useDispatch } from 'react-redux';
import { addToCart } from '../components/Stores/Cart';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 380,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const CardsSection = ({ CardsProducts = [] }) => {
    const { favorites, toggleFavorite } = useFavorites();
    const [products, setProducts] = useState([]);
    const dispatch = useDispatch();
    const { currentUser } = useAuth();
    const [cartStatus, setCartStatus] = useState({}); // Track cart status per product
    const [showPopup, setShowPopup] = useState(false); // Show popup

    const fetchProducts = async () => {
        try {
            const productsCollection = collection(db, "products");
            const productsSnapshot = await getDocs(productsCollection);
            const productsList = productsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setProducts(productsList);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const handleAddToCart = async (product) => {
        console.log("Full product object:", product);

        const productData = {
            productId: product.id,
            title: product.title || product[1],
            price: product.discountPrice && !isNaN(Number(product.discountPrice)) 
                ? Number(product.discountPrice) 
                : product.basePrice && !isNaN(Number(product.basePrice)) 
                ? Number(product.basePrice) 
                : 0,
            quantity: 1,
            img: product.imgUrl,
        };

        console.log("Product data before adding to cart:", product);
        console.log("Product Data:", productData);

        if (!productData.productId || !productData.title || !productData.price || !productData.img) {
            console.error("Invalid product data", productData);
            return;
        }

        if (!productData.price) {
            console.error("Price could not be determined for product:", product);
            return;
        }

        dispatch(addToCart(productData));

        if (currentUser) {
            try {
                const userCartRef = doc(db, 'cart', currentUser.uid);
                await setDoc(userCartRef, {
                    items: arrayUnion(productData)
                }, { merge: true });
            } catch (error) {
                console.error("Error adding to cart:", error);
            }
        } else {
            let guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
            const existingProductIndex = guestCart.findIndex(item => item.productId === product.id);

            if (existingProductIndex >= 0) {
                guestCart[existingProductIndex].quantity += 1;
            } else {
                guestCart.push(productData);
            }

            localStorage.setItem('guestCart', JSON.stringify(guestCart));
        }
    };

    const displayProducts = CardsProducts.length ? CardsProducts : products;

    return (
        <div className='container'>
            <div className="cards-section-container">
                <div className='cards-section-titles'>
                    <h2>Our Best Products</h2>
                    <p>Check our best seller products on our website right now</p>
                </div>

                 <Slider {...settings}>
                     {displayProducts.length > 0 && displayProducts.map((product) => (
                        <div key={product.id} className="cards-container-section1">
                            <div className='productCard-section1'>
                                <div className="productImage-container1">
                                    <img src={product.imgUrl} alt={product.title} className="product-image1" />
                                    <div className="productCard__heart-wrapper1">
                                        <FaHeart
                                            className="productCard__heart1"
                                            onClick={() => toggleFavorite(product)}
                                            style={{ color: favorites.some(fav => fav.id === product.id) ? 'red' : '#C4CDD5' }} 
                                        />
                                    </div>
                                </div>
                                <div className="productCard__content-section1">
                                    <h3 className="productName1">{product.title || product[1]}</h3>
                                    <p className="productType1">{product.type || product[2]}</p>
                                    <div className="card-price1">
                                        <div className="productPrice1">
                                            <p>${product.basePrice || product[4]}</p>
                                        </div>
                                        <div className="productRating1">
                                            {[...Array(product.star)].map((_, index) => (
                                                <FaStar key={index} />
                                            ))}
                                        </div>
                                    </div>


                                    <div className="productCard__buttons1">
                                        {/* <button
                                            className="productCard__button productCard__button--purple1"
                                            onClick={() => handleAddToCart(product)}
                                        >
                                            <IoCart className='filter-cart-icon1' />
                                            Add To Cart
                                        </button> */}

                                        <button
                                        className="productCard__button productCard__button--purple1"
                                        onClick={() => handleAddToCart(product)}
                                        disabled={cartStatus[product.id]}
                                    >
                                        {cartStatus[product.id] ? <IoArrowForward className='added-icon' /> : <IoCart className='filter-cart-icon1' />}
                                        {cartStatus[product.id] ? "Added" : "Add To Cart"}
                                    </button>
                                        <Link to={`/product/${product.id}`}>
                                            <button className="productCard__button productCard__button--gray1">Quick View</button>
                                        </Link>
                                    </div>
                               </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default CardsSection;


