import React from "react";
import "./ProductData.css";

const ProductData = () => {
    const productDetails = [
        "Graphics: Integrated AMD Radeon Graphics ",
        "Chipset: AMD SoC Platform ",
        " Memory: 4GB Soldered DDR4-2400",
        "Memory Slots: One memory soldered to systemboard, one DDR4 SO-DIMM slot, dual-channel capableMax",
        " Memory: Up to 12GB (4GB soldered + 8GB SO-DIMM) DDR4-2400 offering",
        "Storage: 256GB SSD M.2 2242 PCIe 3.0x4 NVM ",
        "Storage Support: One drive, up to 1TB M.2 2242 SSD or 512GB M.2 2280 SSD ",
        "Storage Slot: One M.2 2280 PCIe Gen 3x4 slot, supports M.2 2242/2280 SSD (M.2 SSD model)",
        "Card Reader: 4-in-1 Card ReaderOptical: None",
        "Audio Chip: High Definition (HD) Audio",
        "Speakers: Stereo speakers, 1.5W x2, Dolby",
        "AudioCamera: HD 720p with Privacy Shutter"
    ];

    return (
        <div className="container">
            <section className="product-data">
                <div className="product-data__title">
                    <h2>Detail Product</h2>
                </div>
                <div className="product-data__points">
                    <h4>Specification Super Zeplin M26 (2022) - GG726XM :</h4>
                    {productDetails.map((productDetails, index) => (
                        <p key={index}>{index + 1}. {productDetails}</p>
                    ))}
                </div>
            </section>
         </div>
    );
};

export default ProductData;
