import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import { FaStar, FaHeart } from 'react-icons/fa';
import { IoCart } from 'react-icons/io5';
import Slider from 'react-slick';
import { useFavorites } from './MyCart/FavoritesContext'; 


import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../components/Stores/Cart';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './NewArrival.css';
import 'react-toastify/dist/ReactToastify.css';


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 380,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      },
    },
  ],
};


// export const defaultProducts = [
//   {
//     id: '1',
//     img: '../assets/arrival1.png',
//     title: 'Hardware',
//     star: 5,
//     reviews: 4,
//     newPrice: '$30',
//     productType: 'AK-900 Wired Keyboard',
//     slug: 'ak-900-wired-keyboard',
//   },
//   {
//     id: '2',
//     img: '../assets/arrival2.png',
//     title: 'Gaming',
//     star: 5,
//     reviews: 3,
//     newPrice: '$50',
//     productType: 'HAVIT HV-G92 Gamepad',
//     slug: 'havit-hv-g92-gamepad',
//   },
//   {
//     id: '3',
//     img: '../assets/arrival3.png',
//     title: 'Hardware',
//     star: 3,
//     reviews: 3,
//     newPrice: '$1725',
//     productType: 'RGB liquid CPU Cooler',
//     slug: 'rgb-liquid-cpu-cooler',
//   },
//   {
//     id: '4',
//     img: '../assets/arrival4.png',
//     title: 'Hardware',
//     star: 4,
//     reviews: 3,
//     newPrice: '$400',
//     productType: 'IPS LCD Gaming Monitor',
//     slug: 'ips-lcd-gaming-monitor',
//   },
// ];

const NewArrival = ({ CardsProducts = [] }) => {
  const { favorites, toggleFavorite } = useFavorites(); // Get context values
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  const [selectedProduct, setSelectedProduct] = useState(null);
  


  
  const fetchProducts = async () => {
    try {
        const productsCollection = collection(db, "products");
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        setProducts(productsList);
    } catch (error) {
        console.error("Error fetching products:", error);
    }
};

useEffect(() => {
    fetchProducts();
}, []);

const handleAddToCart = async (product) => {
    console.log("Full product object:", product);

    const productData = {
        productId: product.id,
        title: product.title || product[1],
        price: product.discountPrice && !isNaN(Number(product.discountPrice)) 
            ? Number(product.discountPrice) 
            : product.basePrice && !isNaN(Number(product.basePrice)) 
            ? Number(product.basePrice) 
            : 0,
        quantity: 1,
        img: product.imgUrl,
    };

    console.log("Product data before adding to cart:", product);
    console.log("Product Data:", productData);

    if (!productData.productId || !productData.title || !productData.price || !productData.img) {
        console.error("Invalid product data", productData);
        return;
    }

    if (!productData.price) {
        console.error("Price could not be determined for product:", product);
        return;
    }

    dispatch(addToCart(productData));

    if (currentUser) {
        try {
            const userCartRef = doc(db, 'cart', currentUser.uid);
            await setDoc(userCartRef, {
                items: arrayUnion(productData)
            }, { merge: true });
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    } else {
        let guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
        const existingProductIndex = guestCart.findIndex(item => item.productId === product.id);

        if (existingProductIndex >= 0) {
            guestCart[existingProductIndex].quantity += 1;
        } else {
            guestCart.push(productData);
        }

        localStorage.setItem('guestCart', JSON.stringify(guestCart));
    }
};

const displayProducts = CardsProducts.length ? CardsProducts : products;


  // const handleQuickView = (product) => {
  //   setSelectedProduct(product);
  //   navigate('/product-detail', { state: { product } });
  // };


  return (
    <div className='container'>
      <div className="cards-section-container">
        <div className='cards-section-titles'>
          <h2>New Arrival</h2>
          <p>Check out our recently added products</p>
        </div>
        <Slider {...settings}>
          {displayProducts.length > 0 && displayProducts.map((product) => (
            <div key={product.id} className='cards-container-section1'>
              <div className='productCard-section1'>
                <div className="productImage-container1">
                  <img src={product.imgUrl} alt={product.productType} className="product-image1" />
                  <div className="productCard__heart-wrapper1">
                    <FaHeart
                      className="productCard__heart1"
                      onClick={() => toggleFavorite(product)} // Toggle favorite
                      style={{ color: favorites.some(fav => fav.id === product.id) ? 'red' : '#C4CDD5' }} // Show filled heart if in favorites
                    />
                  </div>
                </div>
                <div className="productCard__content-section1">
                  <h3 className="productName1">{product.title}</h3>
                  <h3 className="productType1">{product.productType}</h3>
                  <div className="card-price1">
                    <div className="productPrice1">
                      {/* <del>{product.prevPrice}</del> {product.newPrice} */}
                      <p>${product.basePrice || product[4]}</p>

                    </div>
                    <div className="productRating1">
                      {[...Array(product.star)].map((_, index) => (
                        <FaStar key={index} />
                      ))}
                    </div>
                  </div>
                  <div className="productCard__buttons1">
                    <button
                      className="productCard__button productCard__button--purple1"
                      onClick={() => handleAddToCart(product)}
                    >
                      <span>
                        <IoCart className='filter-cart-icon1' />
                      </span>
                      <span>
                        Add To Cart
                      </span>
                    </button>
                    <Link to={`/product/${product.slug}`}>
                      <button className="productCard__button productCard__button--gray1">Quick View</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default NewArrival;
