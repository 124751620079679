import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB0OyILwzaM-4MpTYphP70L8dWmq0PpRM0",
  authDomain: "electro-deals-hubs-9c693.firebaseapp.com",
  databaseURL: "https://electro-deals-hubs-9c693-default-rtdb.firebaseio.com",
  projectId: "electro-deals-hubs-9c693",
  storageBucket: "electro-deals-hubs-9c693.appspot.com",
  messagingSenderId: "991712006586",
  appId: "1:991712006586:web:ca12d7c837312399a04d83"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

 const db = getFirestore(app);

export { app, auth, db };
