import React from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import footer from '../../assets/13.png';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (

        <footer className='footer'>
            <div className="container">
                <div className="footer-row">

                    <div className="footer-col first">
                        <h4>Electro Deals Hub - Online Electronic Market</h4>
                        <div className="footer-phone">
                            <span className="footer-txt">customers service 24/7</span>
                            <span className="footer-txt">(025) 3686 25 16</span>
                            <span className="footer-txt">257 Thatcher Road St, Brooklyn, Manhattan,
                                <br />NY 10092
                            </span>
                            <span className="footer-txt"> www.electrodealshub.com</span>
                        </div>

                    </div>

                    <div className="footer-col second">
                        <h4>Store</h4>

                        <div className='footer-ul'>
                            <ul>
                                <li className='footer-link'><Link to='/about'>About Us</Link></li>
                                <li className='footer-link' ><Link to='/contact'>Contact </Link></li>
                                <li className='footer-link'><Link to='/customer-service'>Customer Service</Link></li>
                            </ul>
                            <ul>
                                <li className='footer-link'><Link to='/frequently'>F&Q</Link></li>
                                <li className='footer-link'><Link to='/policy'>Policy and privacy</Link></li>
                            </ul>
                        </div>

                    </div>


                    <div className="footer-col third">

                        <div className="footer-logo">
                            <img src={footer} alt="Logo" />
                        </div>
                        <h4>Follow Us </h4>
                        <div className="social-links">
                            <FaFacebook className='footer-icon'  />
                            <FaTwitter className='footer-icon' />
                            <FaLinkedin className='footer-icon' />
                            <FaInstagram className='footer-icon' />
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ marginBottom: "30px", color: "#C7C7C7" }} />
            <p className="styled-paragraph" style={{ marginBottom: "0", paddingBottom: "20px" }}>2024, Inc. All rights reserved |  Electro Deals Hub</p>
        </footer>
    );
};

export default Footer;
