import './ShoppingCart.css'
import Footer from "../Main/Footer";
import OffcanvasExample from "../../Filteration/Navigation/Nav";
import { useSelector, useDispatch } from 'react-redux';

import React, { useState, useEffect } from "react";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/authContext";
import { Navigate, useNavigate } from 'react-router-dom';
import { addToCart } from '../Stores/Cart';

import ChatBotButton from '../Main/ChatBotButton';

const ShoppingCart = () => {
    const carts = useSelector(store => store.cart.items); // Get cart items from Redux
    const [quantity, setQuantity] = useState({});
    const dispatch = useDispatch();

    const { currentUser } = useAuth();
    const [cartItems, setCartItems] = useState([]);
    const navigate = useNavigate();


    const parsePrice = (priceStr) => {
        // Safely handle newPrice parsing
        if (priceStr && typeof priceStr === 'string') {
            return parseFloat(priceStr.replace(/[^0-9.]/g, '')) || 0;
        }
        return 0;
    };

    // Calculate the total for all items in the cart
    const calculateTotal = () => {
        const discount = 15.00;
        const delivery = 5.00;
        const itemTotal = carts.reduce((total, item) => {
            const price = parsePrice(item.newPrice);
            const qty = quantity[item.productId] || item.quantity;
            return total + (price * qty);
        }, 0);
        const vat = itemTotal * 0.10; 
        return (itemTotal + delivery + vat - discount).toFixed(2);
    };

    const handleContinueShopping = () => {
        navigate('/products');
    }
    const handleShoppingPayment = () => {
        navigate('/payment');
        // navigate('/checkout');
    }

    const handleRemove = async (itemId) => {
        try {
            const userCartRef = doc(db, "cart", currentUser.uid);
            const cartDoc = await getDoc(userCartRef);

            if (cartDoc.exists()) {
                const currentItems = cartDoc.data().items || [];
                const updatedItems = currentItems.filter((item) => item.id !== itemId);
                await updateDoc(userCartRef, {
                    items: updatedItems,
                });
                setCartItems(updatedItems);
            }
        } catch (error) {
            console.error("Error removing item from cart:", error);
        }
    };


    const fetchCartItems = async () => {
        if (currentUser) {
            try {
                const userCartRef = doc(db, "cart", currentUser.uid);
                const cartDoc = await getDoc(userCartRef);
                if (cartDoc.exists()) {
                    setCartItems(cartDoc.data().items || []);
                } else {
                    console.log("No cart found for user.");
                }
            } catch (error) {
                console.error("Error fetching cart items:", error);
            }
        }
    };

    useEffect(() => {
        fetchCartItems();
    }, [currentUser]);


    const totalAmount = cartItems.reduce((total, item) => {
        if (item.price !== undefined && item.price !== null) {
            const price = typeof item.price === 'number' ? item.price : parseFloat(item.price) || 0;
            const itemTotal = price * (item.quantity || 1); // Multiply by quantity, defaulting to 1 if not set
            return total + itemTotal;
        } else {
            return total;
        }
    }, 0).toFixed(2);

    //   console.log("Total Amount:", totalAmount);

    const handleQuantityChange = (e, index) => {
        const newQuantity = parseInt(e.target.value, 10) || 1;
        const updatedCartItems = [...cartItems];
        updatedCartItems[index].quantity = newQuantity; // update quantity
        updatedCartItems[index].finalPrice = parseFloat(updatedCartItems[index].price) * newQuantity; // update final price with new quantity
        setCartItems(updatedCartItems);
    };

    //     const totalItemsInCart = cartItems.reduce((total, item) => total + (item.quantity || 0), 0);
    // console.log("Total Amount:", totalItemsInCart);

    return (
        <>
            <OffcanvasExample />
            <div className="container">
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row item_summary_container">
                        <div className="col-md-9 item__card">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span className="pull-right">
                                        (<strong>{cartItems.length}</strong>) items
                                    </span>
                                    <h5>Items in your cart</h5>
                                </div>
                                <div className="ibox-content">
                                    <div className="table-responsive">
                                        <table className="table shoping-cart-table">
                                            {/* <tbody>
                      <tr>
                        <td width="90">
                          <div className="cart-product-imitation"></div>
                        </td>
                        <td className="desc">
                          <h3>
                            <a href="#" className="text-navy">
                              Desktop publishing software
                            </a>
                          </h3>
                          <p className="small">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is
                          </p>
                          <dl className="small m-b-none">
                            <dt>Description lists</dt>
                            <dd>A description list is perfect for defining terms.</dd>
                          </dl>
                          <div className="m-t-sm">
                        
                            <a href="#" className="text-muted">
                              <i className="fa fa-trash"></i> Remove item
                            </a>
                          </div>
                        </td>
                        <td>
                          $180,00
                          <s className="small ">$230,00</s>
                        </td>
                        <td width="65">
                          <input type="text" className="form-control" placeholder="1" />
                        </td>
                        <td>
                          <h4>$180,00</h4>
                        </td>
                      </tr>
                    </tbody> */}
                                            <tbody>
                                                {cartItems.map((item, index) => (
                                                    <tr key={index}>
                                                        <td width="90">
                                                            <img src={item.img} alt={item.title} className="cart-product-image" />
                                                        </td>

                                                        <td className="desc">
                                                            <h3>
                                                                <span href="#" className="text-navy">
                                                                    {item.title}
                                                                </span>
                                                            </h3>
                                                            <p className="small">
                                                                {item.description}
                                                            </p>
                                                            <dl className="small m-b-none">
                                                                <dt>Description lists</dt>
                                                                <dd>A description list is perfect for defining terms.</dd>
                                                            </dl>
                                                            <div className="m-t-sm">
                                                                <span
                                                                    className="text-muted"
                                                                    onClick={() => handleRemove(item.id)}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <i className="fa fa-trash"></i> Remove item
                                                                </span>

                                                            </div>
                                                        </td>

                                                        {/* <td>
                                                            {item.price}
                                                            <s className="small original-price">$230.00</s>
                                                        </td> */}

                                                        <td>{(parseFloat(item.price) * item.quantity).toFixed(2)}</td>
                                                        <td width="65">
                                                            <input
                                                                type="number"
                                                                className="form-control quantity-input"
                                                                placeholder="1"
                                                                min={1}
                                                                value={item.quantity}
                                                                onChange={(e) => handleQuantityChange(e, index)} // استدعاء الدالة عند تعديل الكمية
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="ibox-content">
                                    <button className="checkout_btn" onClick={handleShoppingPayment}>
                                        <i className="fa fa-shopping-cart"></i> Checkout
                                    </button>
                                    {/* <button className="btn btn-white" onClick={handleContinueShopping}>
                                        <i className="fa fa-arrow-left"></i> Continue shopping
                                    </button> */}
                                </div>
                            </div>
                        </div>

                        {/* Cart Summary and Support */}
                        <div className="col-md-3 summary__card">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>Summary</h5>
                                </div>
                                <div className="ibox-content">
                                    <span>Total</span>
                                    <h2 className="font-bold">${totalAmount}</h2>
                                    <hr />


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <ChatBotButton />

            <Footer />
        </>

    );
};

export default ShoppingCart;
