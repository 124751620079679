// // import React, { useState } from 'react';
// // import { useLocation } from 'react-router-dom';
// // import './CheckOut.css';

// // const CheckOut = () => {
// //     const location = useLocation();
// //     const { img, title, productType, newPrice } = location.state || {};
// //     const [quantity, setQuantity] = useState(1);

// //     // Ensure newPrice is a valid number
// //     const parsedNewPrice = parseFloat(newPrice) || 0;

// //     const incrementQuantity = () => {
// //         setQuantity(quantity + 1);
// //     };

// //     const decrementQuantity = () => {
// //         if (quantity > 1) {
// //             setQuantity(quantity - 1);
// //         }
// //     };

// //     const calculateTotal = () => {
// //         const itemTotal = parsedNewPrice * quantity;
// //         const discount = 15.00;
// //         const delivery = 5.00;
// //         const vat = itemTotal * 0.10;
// //         return (itemTotal + delivery + vat - discount).toFixed(2);
// //     };

// //     return (
// //         <div className='container'>  
// //                 <div className="right-card">
// //                     <div className="right-section1">
// //                         <span className='sub-title'>Current Order</span>
// //                         <p>The sum of all total payments for goods there</p>
// //                     </div>
// //                     <div className="right-section2">
// //                         <div className="right-div">
// //                             <div className="left-div">
// //                                 <img src={img} alt={title} />
// //                             </div>
// //                             <div className='item-info'>
// //                                 <div className="top-row ">
// //                                     <div className='item-title'>
// //                                         <span className='item-name'>{productType}</span>
// //                                     </div>
// //                                     <div>
// //                                         <span className='item-price'>${newPrice}</span>
// //                                     </div>
// //                                 </div>
// //                                 <div className="bottom-row ">
// //                                     <span>Quantity:</span>
// //                                     <span>
// //                                         <button onClick={incrementQuantity}>+</button>
// //                                         <span> {quantity}</span>
// //                                         <button onClick={decrementQuantity}>-</button>
// //                                     </span>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                     <div className='right-card-info'>
// //                         <div className="right-section-bottom">
// //                             <span className='sub-title'>Subtotal</span>
// //                             <span>{(parsedNewPrice * quantity).toFixed(2)}</span>
// //                         </div>
// //                         <div className="right-section-bottom">
// //                             <span className='sub-title'>Items</span>
// //                             <span>{quantity}x</span>
// //                         </div>
// //                         <div className="right-section-bottom">
// //                             <span className='sub-title'>Code Promo</span>
// //                             <span>- $15.00</span>
// //                         </div>
// //                         <div className="right-section-bottom">
// //                             <span className='sub-title'>Delivery Service</span>
// //                             <span>$5.00</span>
// //                         </div>
// //                         <div className="right-section-bottom">
// //                             <span className='sub-title'>Vat (10%)</span>
// //                             <span>${(parsedNewPrice * quantity * 0.10).toFixed(2)}</span>
// //                         </div>
// //                     </div>
// //                     <div className="right-section-btn">
// //                         <button>Pay ${calculateTotal()}</button>
// //                     </div>
// //                 </div>
// //             </div>
// //     );
// // };

// // export default CheckOut;


// import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import './CheckOut.css';

// const CheckOut = () => {
//     const carts = useSelector(store => store.cart.items); // Get cart items from Redux
//     const [quantity, setQuantity] = useState({});

//     // Initialize quantities from the cart items
//     useState(() => {
//         const initialQuantities = carts.reduce((acc, item) => {
//             acc[item.productId] = item.quantity;
//             return acc;
//         }, {});
//         setQuantity(initialQuantities);
//     }, [carts]);

//     const handleIncrementQuantity = (productId) => {
//         setQuantity(prevQuantities => ({
//             ...prevQuantities,
//             [productId]: prevQuantities[productId] + 1
//         }));
//     };

//     const handleDecrementQuantity = (productId) => {
//         setQuantity(prevQuantities => ({
//             ...prevQuantities,
//             [productId]: prevQuantities[productId] > 1 ? prevQuantities[productId] - 1 : 1
//         }));
//     };

//     const parsePrice = (priceStr) => {
//         // Check if the price exists and is a string before attempting to replace
//         if (priceStr && typeof priceStr === 'string') {
//             return parseFloat(priceStr.replace(/[^0-9.]/g, '')) || 0;
//         }
//         return 0; // Return 0 if price is undefined or invalid
//     };

//     // Function to calculate the total for all items in the cart
//     const calculateTotal = () => {
//         const discount = 15.00;
//         const delivery = 5.00;
//         const itemTotal = carts.reduce((total, item) => {
//             const price = parsePrice(item.newPrice);
//             const qty = quantity[item.productId] || item.quantity;
//             return total + (price * qty);
//         }, 0);
//         const vat = itemTotal * 0.10; // Assuming 10% VAT
//         return (itemTotal + delivery + vat - discount).toFixed(2);
//     };

//     return (
//         <div className='container'>
//             <div className="right-card">
//                 <div className="right-section1">
//                     <span className='sub-title'>Current Order</span>
//                     <p>The sum of all total payments for goods there</p>
//                 </div>

//                 {/* Cart items display */}
//                 <div className="right-section2">
//                     {carts.map((item) => (
//                         <div key={item.productId} className="right-div">
//                             <div className="left-div">
//                                 <img src={item.img} alt={item.title} />
//                             </div>
//                             <div className='item-info'>
//                                 <div className="top-row">
//                                     <div className='item-title'>
//                                         <span className='item-name'>{item.productType}</span>
//                                     </div>
//                                     <div>
//                                         <span className='item-price'>
//                                             ${(parsePrice(item.newPrice) * quantity[item.productId]).toFixed(2)}
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <div className="bottom-row">
//                                     <span>Quantity:</span>
//                                     <span>
//                                         <button onClick={() => handleIncrementQuantity(item.productId)}>+</button>
//                                         <span> {quantity[item.productId]}</span>
//                                         <button onClick={() => handleDecrementQuantity(item.productId)}>-</button>
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>

//                 {/* Order summary */}
//                 <div className='right-card-info'>
//                     <div className="right-section-bottom">
//                         <span className='sub-title'>Subtotal</span>
//                         <span>{carts.reduce((total, item) => total + parsePrice(item.newPrice) * quantity[item.productId], 0).toFixed(2)}</span>
//                     </div>
//                     <div className="right-section-bottom">
//                         <span className='sub-title'>Items</span>
//                         <span>{carts.reduce((sum, item) => sum + quantity[item.productId], 0)}x</span>
//                     </div>
//                     <div className="right-section-bottom">
//                         <span className='sub-title'>Code Promo</span>
//                         <span>- $15.00</span>
//                     </div>
//                     <div className="right-section-bottom">
//                         <span className='sub-title'>Delivery Service</span>
//                         <span>$5.00</span>
//                     </div>
//                     <div className="right-section-bottom">
//                         <span className='sub-title'>Vat (10%)</span>
//                         <span>${(carts.reduce((total, item) => total + parsePrice(item.newPrice) * quantity[item.productId], 0) * 0.10).toFixed(2)}</span>
//                     </div>
//                 </div>

//                 {/* Checkout button */}
//                 <div className="right-section-btn">
//                     <button>Pay ${calculateTotal()}</button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default CheckOut;



import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './CheckOut.css';

const CheckOut = () => {
    const carts = useSelector(store => store.cart.items); // Get cart items from Redux
    const [quantity, setQuantity] = useState({});

    // Initialize quantities from the cart items
    useEffect(() => {
        const initialQuantities = carts.reduce((acc, item) => {
            acc[item.productId] = item.quantity; // Ensure each item has its own quantity
            return acc;
        }, {});
        setQuantity(initialQuantities);
    }, [carts]);

    const handleIncrementQuantity = (productId) => {
        setQuantity(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 1) + 1 // Increment specific item quantity
        }));
    };

    const handleDecrementQuantity = (productId) => {
        setQuantity(prevQuantities => ({
            ...prevQuantities,
            [productId]: Math.max(1, (prevQuantities[productId] || 1) - 1) // Decrement but ensure min is 1
        }));
    };

    const parsePrice = (priceStr) => {
        // Safely handle newPrice parsing
        if (priceStr && typeof priceStr === 'string') {
            return parseFloat(priceStr.replace(/[^0-9.]/g, '')) || 0;
        }
        return 0;
    };

    // Calculate the total for all items in the cart
    const calculateTotal = () => {
        const discount = 15.00;
        const delivery = 5.00;
        const itemTotal = carts.reduce((total, item) => {
            const price = parsePrice(item.newPrice);
            const qty = quantity[item.productId] || item.quantity;
            return total + (price * qty);
        }, 0);
        const vat = itemTotal * 0.10; // Assuming 10% VAT
        return (itemTotal + delivery + vat - discount).toFixed(2);
    };

    return (
        <div className='container'>
            <div className="right-card">
                <div className="right-section1">
                    <span className='sub-title'>Current Order</span>
                    <p>The sum of all total payments for goods there</p>
                </div>

                {/* Cart items display */}
                <div className="right-section2">
                    {carts.map((item) => (
                        <div key={item.productId} className="right-div">
                            <div className="left-div">
                                <img src={item.img} alt={item.title} />
                            </div>
                            <div className='item-info'>
                                <div className="top-row">
                                    <div className='item-title'>
                                        <span className='item-name'>{item.productType}</span>
                                    </div>
                                    <div>
                                        <span className='item-price'>
                                            ${(parsePrice(item.newPrice) * quantity[item.productId]).toFixed(2)}
                                        </span>
                                    </div>
                                </div>
                                <div className="bottom-row">
                                    <span>Quantity:</span>
                                    <span>
                                        <button onClick={() => handleIncrementQuantity(item.productId)}>+</button>
                                        <span> {quantity[item.productId]}</span>
                                        <button onClick={() => handleDecrementQuantity(item.productId)}>-</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Order summary */}
                <div className='right-card-info'>
                    <div className="right-section-bottom">
                        <span className='sub-title'>Subtotal</span>
                        <span>{carts.reduce((total, item) => total + parsePrice(item.newPrice) * quantity[item.productId], 0).toFixed(2)}</span>
                    </div>
                    <div className="right-section-bottom">
                        <span className='sub-title'>Items</span>
                        <span>{carts.reduce((sum, item) => sum + quantity[item.productId], 0)}x</span>
                    </div>
                    <div className="right-section-bottom">
                        <span className='sub-title'>Code Promo</span>
                        <span>- $15.00</span>
                    </div>
                    <div className="right-section-bottom">
                        <span className='sub-title'>Delivery Service</span>
                        <span>$5.00</span>
                    </div>
                    <div className="right-section-bottom">
                        <span className='sub-title'>Vat (10%)</span>
                        <span>${(carts.reduce((total, item) => total + parsePrice(item.newPrice) * quantity[item.productId], 0) * 0.10).toFixed(2)}</span>
                    </div>
                </div>

                {/* Checkout button */}
                <div className="right-section-btn">
                    <button>Pay ${calculateTotal()}</button>
                </div>
            </div>
        </div>
    );
};

export default CheckOut;
