import React, { useState, useEffect } from 'react';
import { useNavigate , useParams} from 'react-router-dom';
// import { useParams } from 'react-router-dom'; // For accessing URL parameters
import './ProductDetail.css';
import { RiShoppingBagFill } from 'react-icons/ri';
import { defaultProducts } from '../components/NewArrival';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../components/Stores/Cart';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const ProductDetail = () => {
  // const { slug } = useParams(); // Get the slug from URL
  const { id } = useParams(); // Get the id from URL
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isAddedToCart, setIsAddedToCart] = useState(false); // New state to track if item is added

  const dispatch = useDispatch();
  const navigate = useNavigate();


  // useEffect(() => {
  //   // Find the product based on the slug from the URL
  //   const selectedProduct = defaultProducts.find((item) => item.id === id);
  //   setProduct(selectedProduct);
  // }, [id]);



  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const docRef = doc(db, "products", id);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          const data = docSnap.data();
          setProduct(data);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCart = (product) => {
    dispatch(
      addToCart({
        productId: product.id,
        quantity: quantity,
      })
    );
    setIsAddedToCart(true); // Set item as added
  };

  if (!product) {
    return <div>Loading...</div>; // Add a loading state
  }

  const goToCheckOutPage = () => {
    navigate('/payment', {
      // state: { img, title, productType, reviews, prevPrice, newPrice }
    });
  };


  return (
    <div className="container">
      <section className="product-detail-container">
        <div className="product-images-left">
          <img src={product.imgUrl} alt={product.title} className="large-image-product" />
          <div className="small-images-product">
            <img src={product.imgUrl2} alt={product.title} />
            <img src={product.imgUrl3} alt={product.title} />
            <img src={product.imgUrl4} alt={product.title} />
          </div>
        </div>

        <div className="product-info-center">
          <div className="product-details-container">
            <div className="product-header-title">
              <span className="product-category">{product.title}</span>
              <div>
                <span className="product-title">{product.productType}</span>
              </div>
              <div className="product-rating">
                <div className="stars">{product.rate}</div>
                <span>(4.7) - 390 Product Sold</span>
              </div>
              <span className="product-price">{product.newPrice}</span>
              <p className="txt">
                We provide a <span className="mid-sentence">one-year warranty </span>in case there are any issues with our products.
              </p>
            </div>

            <div className="product-colors">
              <div className="colors-option">
                <span>Choose Color</span>
                <div className="cards">
                  <div className="color-card">Black</div>
                  <div className="color-card">Gray</div>
                  <div className="color-card">White</div>
                  <div className="color-card">Red</div>
                </div>
              </div>

              <div className="memory-option">
                <span>Memory (RAM)</span>
                <div className="cards">
                  <div className="memory-size">4GB</div>
                  <div className="memory-size">8GB</div>
                  <div className="memory-size">12GB</div>
                  <div className="memory-size">24GB</div>
                </div>
              </div>

              <div className="quantity-cont">
                <span>Quantity</span>
                <div className="quantity">
                  <span className="plus" onClick={handleIncrease}>
                    +
                  </span>
                  <span className="num">{quantity}</span>
                  <span className="minus" onClick={handleDecrease}>
                    -
                  </span>
                </div>
              </div>
            </div>

            <div className="product-actions">
              <button className="buy-btn" onClick={goToCheckOutPage}>Buy Now</button>
              <button
                className="add-btn"
                onClick={() => handleAddToCart(product)}
                disabled={isAddedToCart} // Disable the button if item is already added
              >
                <span>
                  <RiShoppingBagFill />
                </span>
                <span>{isAddedToCart ? 'Added to Cart' : 'Add to Cart'}</span> 
              </button>
            </div>
          </div>
        </div>

        {/* Start the right div */}
        <div className="product-options-right">
          <div className="delivery-options">
            <span className="delivery-options-title">Choose Delivery package</span>
            <div className="delivery-items-cont">
            <div className="delivery-item">
              {/* <label className="label">
              </label> */}

                <div className="inputs-group">
                  <div className="package">
                    <input type="radio" name="delivery" />
                    <span className="package-title">Delivery Package</span>
                  </div>
                  <div className="dollars">
                    <p className="dollars-num">$2</p>
                  </div>
                </div>

                <span className="delivery-time">7 day delivery test</span>
            </div>

            <div className="delivery-item">
              {/* <label className="label">
              </label> */}

                <div className="inputs-group">
                  <div className="package">
                    <input type="radio" name="delivery" />
                    <span className="package-title">Delivery Package</span>
                  </div>
                  <div className="dollars">
                    <p className="dollars-num">$2</p>
                  </div>
                </div>

                <span className="delivery-time">7 day delivery test</span>
            </div>

            <div className="delivery-item">
              {/* <label className="label">
              </label> */}

                <div className="inputs-group">
                  <div className="package">
                    <input type="radio" name="delivery" />
                    <span className="package-title">Delivery Package</span>
                  </div>
                  <div className="dollars">
                    <p className="dollars-num">$2</p>
                  </div>
                </div>

                <span className="delivery-time">7 day delivery test</span>
            </div>
            </div>
          

          </div>


          <div className="customization">
            <span className="customization-title">Code Promo</span>
            <input type="text" placeholder="PR53639" />
            <span>*Voucher $15 has been used</span>
            <button className="submit-btn">Submit</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetail;
