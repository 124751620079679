import React, { useState } from 'react';
import Category from './Category/Category';
import Rating from './Rating/Rating';
import Price from './Price/Price';
import Colors from './Colors/Colors';
import { IoCloseSharp } from "react-icons/io5";
import './Sidebar.css';

  const Sidebar = ({ handleCategoryChange, handlePriceChange, handleRatingChange }) => {
    
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [query, setQuery] = useState("");
    const [priceRange, setPriceRange] = useState({ min: '', max: '' });
    const [selectedRatings, setSelectedRatings] = useState({});
  
    const handlePriceRangeChange = (range) => {
      // Update state with the new price range from Price component
      setPriceRange(range);
    };
  
    const handleRatingFilterChange = (updatedRatings) => {
      setSelectedRatings(updatedRatings);
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);  // State for sidebar visibility

    // Toggle function to open/close sidebar
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

  return (

    <section className={`sidebar${isSidebarOpen ? 'open' : ''}`}>
      <div className="filter-container">
        <span>Filter</span>
        <p className="toggle-button"  onClick={toggleSidebar}><IoCloseSharp className={`close-sidebar ${isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}`} />        
        </p>
      </div>
      <Category handleChange={handleCategoryChange} />
      <Rating onRatingChange={handleRatingChange} />
      <Price handlePriceChange={handlePriceChange}/>
      <Colors handleChange={handleCategoryChange} />
    </section>
  );
};

export default Sidebar;

