import React, { useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Frequently.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import OffcanvasExample from "../Filteration/Navigation/Nav";
import Footer from "../components/Main/Footer";
import ChatBotButton from "../components/Main/ChatBotButton";


const Frequently = () => {

    const conditions = [
        { id: 1, que: "What is the return policy?", term: "You can return the product within 30 days." },
        { id: 2, que: "How to contact support?", term: "You can contact support through email or phone." },
        { id: 3, que: "How to contact support?", term: "You can contact support through email or phone." },
        { id: 4, que: "What is the return policy?", term: "You can return the product within 30 days." },

    ];


    const [activeId, setActiveId] = useState(null);

    const handleToggle = (id) => {
        if (activeId === id) {
            setActiveId(null); // Close if the same item is clicked
        } else {
            setActiveId(id); // Open the clicked item
        }
    };

    return (
        <>
            <OffcanvasExample />
            <div className='frq-main-container' id="terms" >
                <div className="container">
                    <header>
                        <h2 className="questions">Frequently Questions</h2>
                    </header>

                    <div className="container-frq">
                        <div className="accordionBlock">
                            {conditions.map((condition) => (
                                <div key={condition.id} className="condition-item">
                                    <div className="question" onClick={() => handleToggle(condition.id)}>
                                        <h2>{condition.que}</h2>
                                        <p>
                                            {activeId === condition.id ? <IoIosArrowUp /> : <IoIosArrowDown />}
                                        </p>
                                    </div>
                                    <AnimatePresence initial={false}>
                                        {activeId === condition.id && (
                                            <motion.div
                                                key="content"
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: 'auto' }}
                                                exit={{ opacity: 0, height: 0 }}
                                                transition={{ duration: 0.3 }}
                                                className="answers"
                                            >
                                                <p>{condition.term}</p>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <ChatBotButton />
            <Footer  />
        </>
    );
}

export default Frequently;
