import React, { useState } from "react";
import Footer from "../../components/Main/Footer";
import Nav from "../../Filteration/Navigation/Nav";
import SupportContent from "./SupportContent";
import SupportHeader from "./SupportHeader";
import ChatBotButton from "../../components/Main/ChatBotButton";



const CustomerSupport = () => {

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (term) => {
    setSearchTerm(term);
  };
    return (
    <>
    <Nav />
    <SupportHeader onSearch={handleSearch} />
    <SupportContent searchTerm={searchTerm} />
    <ChatBotButton />

    <Footer />
    </>
    );
  }
  
  export default CustomerSupport;
  