import React, { useState } from 'react';
import { IoFilter } from 'react-icons/io5';
import Sidebar from '../Sidebar/Sidebar';
import Pagination from './Pagination';

const Products = ({ result, selectedCategory }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const lastProductIndex = currentPage * productsPerPage;
  const firstProductIndex = lastProductIndex - productsPerPage;
  const currentProducts = result.slice(firstProductIndex, lastProductIndex);

  return (
    <div className="container">
      <div className="container-cards-filter">
        <div className="filter-title">
          <div className="filter-icon" onClick={toggleSidebar}>
            <IoFilter /> Filter
          </div>

          <p className="total-items-filter">
            Showing <span>{firstProductIndex + 1} - {Math.min(lastProductIndex, result.length)}</span> items out of <span>{result.length}</span> possible search results for <span>{selectedCategory || "All Categories"}</span>
          </p>
        </div>

        {showSidebar && (
          <div className="responsive-sidebar">
            <Sidebar />
          </div>
        )}

        <section className="card-container-filtration">
          {currentProducts}

          <Pagination
            totalProducts={result.length}
            productsPerPage={productsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </section>
      </div>
    </div>
  );
};

export default Products;
