import React, { useState } from 'react';
import ReviewsSection from './ReviewsSection';
import ProductData from './ProductData';
import './ReviewsData.css';

const ReviewsData = () => {
    const [activeComponent, setActiveComponent] = useState('reviews');

    const handleComponentSwitch = (component) => {
        setActiveComponent(component);
    };

    return (

        <div className='container'>
            <div className="main-container">

                <div className="top-div">
                    <div className={`tab ${activeComponent === 'product' ? 'active' : ''}`} onClick={() => handleComponentSwitch('product')}>
                        <h2>Product Details</h2>
                    </div>
                    <div className={`tab ${activeComponent === 'reviews' ? 'active' : ''}`} onClick={() => handleComponentSwitch('reviews')}>
                        <h2>Rating & Reviews</h2>
                    </div>
                </div>

                {/* <div className="component-container"> */}
                    {activeComponent === 'reviews' && <ReviewsSection />}
                    {activeComponent === 'product' && <ProductData />}
                {/* </div> */}

            </div>
        </div>
    );
};

export default ReviewsData;
