// 29-8
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Container, Offcanvas } from 'react-bootstrap';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, useDispatch } from 'react-redux'
import { toggleStatusTab } from "../../components/Stores/Cart";
import { FaShoppingCart } from 'react-icons/fa';
import { CgProfile } from "react-icons/cg";
import { RiLogoutBoxFill } from "react-icons/ri";
import { MdFavorite } from "react-icons/md";
// import { FaCartShopping } from "react-icons/fa6";
import { IoMdPerson } from "react-icons/io";

import { CiSearch } from "react-icons/ci";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../firebase";

// import CartTab from "../../components/MyCart/CartTap";

import './Nav.css';
import logo from "../../assets/13.png";
import searchIcon from "../../assets/search-normal.svg";
import shoppingCart from "../../assets/shopping-cart.svg";

import { useAuth } from '../../contexts/authContext'


function OffcanvasExample({ toggleDarkMode, handleInputChange, query, onCategoryChange }) {


  const [show, setShow] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegister, setIsRegister] = useState(false); // Toggle between Login/Register
  const [user, setUser] = useState(null); // Current logged-in user
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const goToFavoritePage = () => {
    navigate('/favorites');
  };

  // const goToMyCartPage = () => {
  //   navigate('/cart');
  // };

  const handleShoppingCart =()=>{
    navigate('/shopping_cart')
  }


  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   setEmail('');
  //   setPassword('');
  // };

  // const handleOverlayClick = (e) => {
  //   if (e.target.classList.contains("modal-overlay")) {
  //     closeModal();
  //   }
  // };

  const toggleRegister = () => {
    setIsRegister(!isRegister);
  };

  const handleAuthAction = (e) => {
    e.preventDefault();
    if (isRegister) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setUser(userCredential.user);
          // closeModal();
        })
        .catch((error) => alert(error.message));
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setUser(userCredential.user);
          // closeModal();
        })
        .catch((error) => alert(error.message));
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => setUser(null));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: toggleDarkMode ? 'dark' : 'light',
    },
  });

  const [userName, setUserName] = useState('');

  // add to cart
  const carts = useSelector(store => store.cart.items); // Get cart items from Redux

  const [cartItems, setCartItems] = useState([]);


  // const cartItems = useSelector((store) => store.cart.items); // Access cart items from Redux store

    // Get cart items from Redux
    // const cartItems = useSelector((store) => store.cart.items);

    // Calculate the total number of items
    // const totalItemsInCart = cartItems.reduce((total, item) => total + (item.quantity || 0), 0);
  
// console.log("cartItems:" , cartItems);

  const [totalQuantity, setTotalQuantity] = useState(0);
  // const carts = useSelector(store => store.cart.items);
  const dispatch = useDispatch();

  const totalAmount = cartItems.reduce((total, item) => {
    if (item.price !== undefined && item.price !== null) {
        const price = typeof item.price === 'number' ? item.price : parseFloat(item.price) || 0;
        const itemTotal = price * (item.quantity || 1); // Multiply by quantity, defaulting to 1 if not set
        return total + itemTotal;
    } else {
        return total;
    }
}, 0).toFixed(2);

const handleQuantityChange = (e, index) => {
  const newQuantity = parseInt(e.target.value, 10) || 1; 
  const updatedCartItems = [...cartItems];
  updatedCartItems[index].quantity = newQuantity; // update quantity
  updatedCartItems[index].finalPrice = parseFloat(updatedCartItems[index].price) * newQuantity; // update final price with new quantity
  setCartItems(updatedCartItems); 
};

  useEffect(() => {
    let total = 0;
    carts.forEach(item => total += item.quantity);
    setTotalQuantity(total);
    
  }, [carts])

  const handleOpenTabCart = () => {
    dispatch(toggleStatusTab());
    // alert('Open Tab Cart')
  }

  const { currentUser } = useAuth()



  // signInWithEmailAndPassword(auth, email, password)
  // .then((userCredential) => {
  //   setUser(userCredential.user);
  //   closeModal();
  // })
  // .catch((userCredential) => {
  //   setUser(userCredential.user);
  //   // closeModal();
  //   // if (error.code === "auth/network-request-failed") {
  //   //   alert("Network error. Please check your internet connection and try again.");
  //   // } else {
  //   //   alert(error.message);
  //   // }
  // });


  const [selectedCategory, setCategory] = useState('All Categories');


  const handleCategoryChange = (event) => {
    const selectedValue = event.target?.value;
    // console.log(selectedValue);
    if (selectedValue) {
      setCategory(selectedValue);
    } else {
      console.error('Category is undefined');
    }
  };

  const [open, setOpen] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isLoggedIn = useAuth().currentUser !== null;

  

  const Menus = [
    {
      text: userName,
      icon: <IoMdPerson />,
    },
    {
      text: 'My Favorites', 
      onClick: goToFavoritePage,
      icon: <MdFavorite />
    },
  ].concat(isLoggedIn ? [
    {
      text: 'Logout', 
      onClick: handleLogout,
      icon: <RiLogoutBoxFill />
    },
  ] : []);

  const menuRef = useRef();
  const imgRef = useRef();

  // Handle clicks outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !imgRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);





// the username / logged in
useEffect(() => {
  if (currentUser) {
    setUserName(currentUser.displayName || currentUser.email);
  } else {
    setUserName('Guest');
  }
}, [currentUser]);

// const [cartItems, setCartItems] = useState([]);


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Navbar
        bg={toggleDarkMode ? "dark" : "white"}
        expand="lg"
        className={`navBar ${toggleDarkMode ? 'navbar-dark' : ''}`}
        fixed="top"
      >
        <Container className="navbar-container">
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            onClick={handleShow}
            className={toggleDarkMode ? 'burger-icon-dark' : 'burger-icon-light'}
          />

          <Navbar.Brand href="/" className="mx-auto">
            <img className="logoImg" src={logo} alt="Logo" />
          </Navbar.Brand>

          <div className="responsive-icons">
            <img src={searchIcon} alt="" />

            <img src={shoppingCart} alt="" className="shopping-cart" onClick={handleShoppingCart} />
          </div>
          {/* <CartTab show={show} onHide={handleClose} carts={carts}  /> */}

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header className={`offcanvas-header ${toggleDarkMode ? 'header-dark' : ''}`}>
              <button
                type="button"
                className={`btn-close ${toggleDarkMode ? 'text-white' : 'text-black'}`}
                aria-label="Close"
                onClick={handleClose}
              ></button>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <img className="logoImg" src={logo} alt="Logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="nav-center">
                <div className="search-container">
                  <CiSearch className="icon" />
                  <input
                    className="search-input"
                    type="text"
                    onChange={handleInputChange}
                    value={query}
                    placeholder="Search something…"
                  />

                </div>
                <div className="category-container">
                  <select value={selectedCategory} onChange={handleCategoryChange}>
                    <option value="All Categories">All Categories</option>
                    <option value="Audios">Audios</option>
                    <option value="Smart Watch">Smart Watch</option>
                  </select>
                  <CiSearch className="category-search-icon" />
                </div>
              </div>
              <div className="d-flex align-items-center relative">
                <FaShoppingCart className="nav-icons"
                  // onClick={goToMyCartPage}
                  // onClick={handleOpenTabCart}
                  onClick={handleShoppingCart}
                />

{/* {cartItems.length > 0 && ( */}
        <span className="absolute top-1/2 left-0 bg-[#FF0000] text-white text-sm text-center p-1
        w-5 h-5 rounded-full flex justify-center items-center">
          {cartItems.length}
          
          {/* {totalItemsInCart} */}

        </span>
      {/* )} */}
                {/* <span className='absolute top-1/2 left-0 bg-[#FF0000] text-white text-sm text-center p-1
                  w-5 h-5 rounded-full flex justify-center items-center'>{cartItems.length}</span> */}

                <div
                  ref={imgRef}
                  onClick={() => setOpen(!open)}
                  className="profile-container"
                >
                  <CgProfile className="person-icon" />
                  {open && (
                    <div ref={menuRef} className="profile-dropdown">
                      <ul>
                      
                        {Menus.map((menu) => (
                          <li
                            className="li-profile"
                            onClick={() => {
                              setOpen(false);
                              // onClick={() => setOpen(false)}
                              if (menu.onClick) menu.onClick();
                            }}
                            key={menu.text}
                          >
                            {menu.icon}
                            {menu.text}
                          </li>
                        ))}

                      </ul>
                    </div>
                  )}
                </div>


                <div className="login-register ms-3">
                  {user ? (
                    <span onClick={handleLogout} className="logout"></span>
                  ) : (
                    <>
                      {/* <span className="login" onClick={openModal}>Login</span> */}
                      <Link className='login' to={'/login'}>Login</Link>

                      <span> or </span>

                      {/* <span className="register" onClick={toggleRegister}>Register</span> */}
                      <Link className='login' to={'/register'}>Register</Link>

                    </>
                  )}
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      {/* Modal */}
      {/* {isModalOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-content animate-modal">
            <button className="close-btn" onClick={closeModal}>
              &times;
            </button>
            <h2>{isRegister ? 'Register' : 'Login'}</h2>
            <form onSubmit={handleAuthAction}>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="login-btn">
                {isRegister ? 'Register' : 'Login'}
              </button>
              <div className="main-toggle">
                {isRegister ? (
                  <span>
                    Already have an account? <span onClick={toggleRegister} className="toggle-link">Login</span>
                  </span>
                ) : (
                  <span>
                    Don't have an account? <span onClick={toggleRegister} className="toggle-link">Register</span>
                  </span>
                )}
              </div>
            </form>
          </div>
        </div>
      )} */}
    </ThemeProvider>
  );
}

export default OffcanvasExample;


