import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import './SupportHeader.css';

const SupportHeader = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value); // Call parent component's function to filter content
  };

  return (
    <div className="support-header">
      <div className='support_header_txt'>
      <h1 className="support-title">How can we help you?</h1>
      <div className="search__container">
        <FaSearch className="search_icon" />
        <input
          type="text"
          placeholder="Search..."
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange} 
        />
      </div>
      </div>
    
    </div>
  );
};

export default SupportHeader;
