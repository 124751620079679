import "./Category.css";
import Input from "../../../components/Input";

function Category({ handleChange }) {
  return (
    <div className="sidebar-container">
      <h2 className="sidebar-title">Category</h2>
      <div className="label-container">
        <label className="sidebar-label-container">
          <input onChange={handleChange} type="radio" value="" name="category" />
          <span className="checkmark"></span>All
        </label>
        <Input
          handleChange={handleChange}
          value="Handphone"
          title="Handphone"
          name="category"
        />
        <Input
          handleChange={handleChange}
          value="Camera"
          title="Camera"
          name="category"
        />
        <Input
          handleChange={handleChange}
          value="Hardware"
          title="Hardware"
          name="category"
        />
        <Input
          handleChange={handleChange}
          value="Audios"
          title="Audios"
          name="category"
        />
        <Input
          handleChange={handleChange}
          value="Smart Watch"
          title="Smart Watch"
          name="category"
        />
        <Input
          handleChange={handleChange}
          value="Gaming"
          title="Gaming"
          name="category"
        />
      </div>
    </div>
  );
}

export default Category;
