import React, { useState } from 'react';
import Products from "../Filteration/Products/Products";
import Nav from '../Filteration/Navigation/Nav';
import Sidebar from '../Filteration/Sidebar/Sidebar';
import Card from "../components/Card";
import products from "../Filteration/db/data";
import ChatBotButton from '../components/Main/ChatBotButton';
// import Footer from '../components/Main/Footer';

const ProductsPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [query, setQuery] = useState("");
  const [priceRange, setPriceRange] = useState({ min: '', max: '' });
  const [selectedRatings, setSelectedRatings] = useState({});

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);  // State for sidebar visibility


  // Toggle function to open/close sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handlePriceChange = (min, max) => {
    setPriceRange({ min, max });
  };

  
  const handleCategoryChange = (event) => {
    const category = (event.target.value);
    setSelectedCategory(category);
  };


  const handleRatingChange = (updatedRatings) => {
    setSelectedRatings(updatedRatings);
  };


  const filteredData = (products, selected, query, priceRange, selectedRatings , selectedCategory) => {
    let filteredProducts = products;
  
    // Filtering by search query
    if (query) {
      filteredProducts = filteredProducts.filter(
        (product) => product.title.toLowerCase().includes(query.toLowerCase())
      );
    }



    if (selectedCategory && selectedCategory !== "All Categories") {
      filteredProducts = filteredProducts.filter(
        (product) => product.category === selectedCategory
      );
    }
  

    // Applying price range filter
    if (priceRange.min || priceRange.max) {
      const min = parseFloat(priceRange.min) || 0;
      const max = parseFloat(priceRange.max) || Infinity;
      filteredProducts = filteredProducts.filter(
        ({ newPrice }) => 
          parseFloat(newPrice.replace(/[^0-9.]/g, '')) >= min && 
          parseFloat(newPrice.replace(/[^0-9.]/g, '')) <= max
      );
    }
  
    // Applying rating filter
    if (Object.keys(selectedRatings).length > 0) {
      filteredProducts = filteredProducts.filter(({ star }) =>
        selectedRatings[star]
      );
    }
  
    // Applying selected category filter
    if (selected) {
      filteredProducts = filteredProducts.filter(
        ({ category, color, company, newPrice, title }) =>
          category === selected ||
          color === selected ||
          company === selected ||
          newPrice === selected ||
          title === selected
      );
    }
  
    return filteredProducts.map(
      ({ img, title, productType, star, reviews, prevPrice, newPrice, id }) => (
        <Card
          key={id}
          img={img}
          title={title}
          productType={productType}
          star={star}
          reviews={reviews}
          prevPrice={prevPrice}
          newPrice={newPrice}
        />
      )
    );
  }
  
  const result = filteredData(products, selectedCategory, query, priceRange, selectedRatings);

  return (
    <div className="products-page">
      <Nav query={query} handleInputChange={handleInputChange} onCategoryChange={handleCategoryChange} />
      <button className="toggle-button"  onClick={toggleSidebar}>
        {isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
      </button>

      <div className='container'>
        <div className="main-content">
          <div className={`sidebar-container ${isSidebarOpen ? 'open' : ''}`}>
          <Sidebar 
            handleCategoryChange={handleCategoryChange} 
            handlePriceChange={handlePriceChange} 
            handleRatingChange={handleRatingChange}
          />
          </div>
        
          <div className="product-cards">
            <Products result={result}  selectedCategory={selectedCategory} query={query} />
            {/* <Products result={filteredData(products, selectedCategory, query, priceRange, selectedRatings)} /> */}
          </div>
        </div>
      </div>
      <ChatBotButton />

      {/* <Footer /> */}
    </div>

   
  );
};

export default ProductsPage;



// import React, { useState, useEffect } from 'react';
// import { collection, getDocs } from 'firebase/firestore';
// import { db } from '../firebase';  
// import Products from "../Filteration/Products/Products";
// import Nav from '../Filteration/Navigation/Nav';
// import Sidebar from '../Filteration/Sidebar/Sidebar';
// import Card from "../components/Card";

// const ProductsPage = () => {
//   const [products, setProducts] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [query, setQuery] = useState("");
//   const [priceRange, setPriceRange] = useState({ min: '', max: '' });
//   const [selectedRatings, setSelectedRatings] = useState({});
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);  // حالة لرؤية الشريط الجانبي

//   const fetchProducts = async () => {
//     try {
//         const productsCollection = collection(db, "products");
//         const productsSnapshot = await getDocs(productsCollection);
//         const productsList = productsSnapshot.docs.map((doc) => ({
//             id: doc.id,
//             ...doc.data(),
//         }));

//         setProducts(productsList);
//     } catch (error) {
//         console.error("Error fetching products:", error);
//     }
// };

// useEffect(() => {
//     fetchProducts();
// }, []);

//   return (
//     <div className="products-page">
//       <Nav query={query} handleInputChange={(e) => setQuery(e.target.value)} onCategoryChange={(e) => setSelectedCategory(e.target.value)} />
//       <button className="toggle-button"  onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
//         {isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
//       </button>

//       <div className='container'>
//         <div className="main-content">
//           <div className={`sidebar-container ${isSidebarOpen ? 'open' : ''}`}>
//             <Sidebar 
//               handleCategoryChange={(e) => setSelectedCategory(e.target.value)} 
//               handlePriceChange={(min, max) => setPriceRange({ min, max })} 
//               handleRatingChange={(updatedRatings) => setSelectedRatings(updatedRatings)}
//             />
//           </div>
          
//           <div className="product-cards">
//             <Products result={products} selectedCategory={selectedCategory} query={query} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductsPage;
