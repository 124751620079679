import React from 'react'
import Nav from '../Filteration/Navigation/Nav';

import imgWhoChoose from '../assets/headphone.jpeg'
import img1 from '../assets/truck.svg'
import img2 from '../assets/bag.svg'
import img3 from '../assets/support.svg'
import img4 from '../assets/return.svg'
import './About.css'

import Footer from '../components/Main/Footer'
import Testimonials from '../components/Testimonials';
import ChatBotButton from '../components/Main/ChatBotButton';

function About() {
    return (
        <>
            <Nav />

            <div className="WhyChoose m-auto w-10/12 py-20">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-36">

                    <div className="left pt-5 ">
                        <div className="boxs grid grid-cols-2 gap-10 mt-10">
                            <div className="box">
                                <div className="imge mb-5 relative">
                                    <img src={img1} alt="" />
                                </div>
                                <h6 className='text-lg my-1 font-semibold'>Fast & Free Shipping</h6>
                                <p className=' text-gray-600 font-normal font-sans'>We believe in making your shopping experience seamless. That's why we offer fast and free shipping on all orders. Whether you're near or far, your products will arrive at your door quickly, with no extra cost.</p>
                            </div>
                            <div className="box">
                                <div className="imge mb-5 relative">
                                    <img src={img2} alt="" />
                                </div>
                                <h6 className='text-lg my-1 font-semibold'>Easy to Shop</h6>
                                <p className=' text-gray-600 font-normal font-sans'>Shopping with us is simple and convenient. Our website is designed to offer a user-friendly shopping experience, allowing you to find what you need with ease. Browse, select, and check out in just a few clicks!</p>
                            </div>
                            <div className="box">
                                <div className="imge mb-5 relative">
                                    <img src={img3} alt="" />
                                </div>
                                <h6 className='text-lg my-1 font-semibold'>24/7 Support</h6>
                                <p className=' text-gray-600 font-normal font-sans'>We're here for you anytime, anywhere. Our dedicated customer service team is available 24/7 to help with any questions, concerns, or issues. Whether it's product inquiries or order assistance, we're just a message away.</p>
                            </div>
                            <div className="box">
                                <div className="imge mb-5 relative">
                                    <img src={img4} alt="" />
                                </div>
                                <h6 className='text-lg my-1 font-semibold'>Hassle Free Returns</h6>
                                <p className=' text-gray-600 font-normal font-sans'>We want you to love what you buy! If something isn't quite right, our hassle-free return policy ensures that returns are simple and straightforward. Shop with confidence knowing that we've got you covered.</p>
                            </div>
                        </div>
                    </div>

                    <div className="about_right">
                        <div className="img relative w-10/12">
                            <img className='w-full rounded-3xl head-phone-img' src={imgWhoChoose} alt="" />
                        </div>
                    </div>

                </div>
            </div>

            <Testimonials />
            <ChatBotButton />
            <Footer />
        </>
    )
}

export default About